import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatButtonModule, MatSnackBarModule, MatCardModule, MatDialogModule, MatListModule, MatNativeDateModule, MatDatepickerModule, MatTabsModule, MatCheckboxModule, MatFormFieldModule, MatIconModule, MatInputModule, MatSelectModule, MatSidenavModule } from '@angular/material';
import { TranslateModule } from '@ngx-translate/core';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { FuseSharedModule } from '@fuse/shared.module';
import { AuthGuardService as AuthGuard } from 'app/auth/auth-guard.service';
import { ClientiComponent } from './clienti.component';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import { ClientiDetailComponent } from './detail/detail.component';
import { AdaugareComponent } from './adaugare/adaugare.component';

const routes = [
    {
        path     : 'clienti',
        component: ClientiComponent,
        canActivate: [AuthGuard]
    },
    {
        path     : 'clienti/adaugare',
        component: AdaugareComponent,
        canActivate: [AuthGuard]
    },
    {
        path     : 'clienti/:cui',
        component: ClientiDetailComponent,
        canActivate: [AuthGuard]
    },
];

@NgModule({
    declarations: [
        ClientiComponent,
        ClientiDetailComponent,
        AdaugareComponent
    ],
    imports     : [
        RouterModule.forChild(routes),
        MatSlideToggleModule,
        TranslateModule,
        MatButtonModule,
        MatCardModule,
        MatNativeDateModule,
        MatDatepickerModule,
        MatListModule,
        MatDialogModule,
        MatSnackBarModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatSelectModule,
        MatSidenavModule,
        MatCheckboxModule,
        MatTabsModule,
        NgxDatatableModule,
        FuseSharedModule
    ],
    exports     : [
        ClientiComponent
    ]
})

export class ClientiModule
{
}

