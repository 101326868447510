import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { environment } from "../../../../../environments/environment";
import {
  MatDialog,
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from "@angular/material";
import pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import { DatePipe } from "@angular/common";

@Component({
  selector: "app-amef-detail",
  templateUrl: "./detail.component.html",
  styleUrls: ["./detail.component.scss"],
})
export class AmefDetailComponent implements OnInit {
  constructor(
    public datepipe: DatePipe,
    private route: ActivatedRoute,
    private http: HttpClient,
    private formBuilder: FormBuilder,
    private dialog: MatDialog,
    public snackBar: MatSnackBar
  ) {}
  seria;
  platitor = "Verificare TVA";

  amef;
  dateFormat = require("dateformat");

  Reprezentant;

  Reprezentanti;
  selectedtab = new FormControl(0);
  form: FormGroup;
  horizontalPosition: MatSnackBarHorizontalPosition = "center";
  verticalPosition: MatSnackBarVerticalPosition = "top";
  ngOnInit() {
    this.seria = this.route.snapshot.params["seria"];

    this.form = this.formBuilder.group({
      serie: new FormControl(),
      client: new FormGroup({
        nume: new FormControl(),
        cui: new FormControl(),
      }),
      model: new FormGroup({
        nume: new FormControl(),
        id: new FormControl(),
      }),
      data_vanzare: new FormControl(),
      data_fiscalizare: new FormControl(),
      SeriaFiscala: new FormControl(),
      actualizare: new FormControl(),
      serieSIM: new FormControl(),
      teh_service: new FormGroup({
        id: new FormControl(),
      }),
      furnizorSIM: new FormControl(),
      punct_de_lucru: new FormGroup({
        id: new FormControl(),
        adresa: new FormControl(),
      }),
    });

    this.getAMEF(this.seria);
  }

  getAMEF(serie) {
    this.http
      .get(environment.url + "/api/amef/amef/" + serie + "/")
      .subscribe((AMEF: any) => {
        this.amef = AMEF;

        this.form.patchValue(this.amef);
        this.http
          .get(
            environment.url +
              "/api/amef/reprezentant/?firma=" +
              this.amef.client.cui
          )
          .subscribe((Reprezentati: any) => {
            this.Reprezentanti = Reprezentati;
          });
      });
  }

  VerificareTVA() {
    var cui = this.amef.client.cui.split("RO", 2);
    if (cui.length == 2) {
      cui.shift();
    }
    this.http
      .get("http://tva.tekit.ro/tva.php?cui=" + cui[0])
      .subscribe((datetva1: any) => {
        if (datetva1 == true) {
          this.platitor = "ESTE platitor";
          this.snackBar.open("ESTE platitor de TVA!", "Inchide", {
            duration: 1200,
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
          });
        }
        if (datetva1 == false) {
          this.platitor = "NU este platitor";
          this.snackBar.open("NU este platitor de TVA!", "Inchide", {
            duration: 1200,
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
          });
        }
      });
  }

  // Create a general service for this function
  public formatDateMaterialInput(dateInput: string) {
    if (dateInput) {
      let date = new Date(dateInput);
      const newDate = new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        date.getHours(),
        date.getMinutes() - date.getTimezoneOffset()
      );
      return new Date(newDate).toISOString().split("T")[0];
    }
    return null;
  }
  ActualizeazaAMEF(amef) {
    amef.data_fiscalizare = this.formatDateMaterialInput(amef.data_fiscalizare);
    this.http
      .put(
        environment.url + "/api/amef/amef/" + amef.serie.toString() + "/",
        amef
      )
      .subscribe(
        () => {
          this.snackBar.open(
            "Datele au fost modificate cu succes!",
            "Inchide",
            {
              duration: 700,
              horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,
            }
          );
          this.getAMEF(this.seria);
        },
        (error) => {
          this.snackBar.open("Eroare de salvare!", "Inchide", {
            duration: 700,
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
          });
        }
      );
  }
}
