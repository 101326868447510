import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { AuthGuardService as AuthGuard } from "app/auth/auth-guard.service";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { environment } from "../../../../../environments/environment";
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from "@angular/material";

@Component({
  selector: "app-adaugare-amef",
  templateUrl: "./adaugare-amef.component.html",
  styleUrls: ["./adaugare-amef.component.scss"],
})
export class AdaugareAMEFComponent implements OnInit {
  producatori: any[];
  Producator: any;
  modele: any[];
  AdaugareMultipla: false;
  Start: any;
  Stop: any;
  form: FormGroup;
  horizontalPosition: MatSnackBarHorizontalPosition = "center";
  verticalPosition: MatSnackBarVerticalPosition = "top";
  constructor(
    private route: ActivatedRoute,
    private http: HttpClient,
    private formBuilder: FormBuilder,
    public snackBar: MatSnackBar
  ) {}

  ngOnInit() {
    this.GetProducatori();
    this.form = this.formBuilder.group({
      serie: [""],
      model: ["", Validators.required],
    });
    this.AdaugareMultipla = false;
  }

  GetProducatori() {
    this.http
      .get(environment.url + "/api/amef/producatori/")
      .subscribe((producatori: any) => {
        this.producatori = producatori;
      });
  }
  GetModele() {
    this.http
      .get(
        environment.url + "/api/amef/modele/?producator=" + this.Producator.id
      )
      .subscribe((modele: any) => {
        this.modele = modele;
      });
  }

  TrimiteAMEF(amef) {
    amef.serie = this.Producator.prefix + amef.serie.toString();
    if (this.AdaugareMultipla == false) {
      this.http
        .post(environment.url + "/api/amef/v-amef/", amef)
        .subscribe(() => {
          this.form.reset();
        });
    } else {
      for (var _i = this.Start; _i <= this.Stop; _i++) {
        amef.serie = this.Producator.prefix + _i.toString();
        this.http
          .post(environment.url + "/api/amef/v-amef/", amef)
          .subscribe(() => {
            this.form.reset();
          });
      }
    }
    this.snackBar.open("AMEF au fost adaugate!", "Inchide", {
      duration: 700,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
    });
  }
}
