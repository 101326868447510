import { Component, OnInit, Input } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../../../../environments/environment";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import {
  MatDialog,
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from "@angular/material";
@Component({
  selector: "app-reprezentanti",
  templateUrl: "./reprezentanti.component.html",
  styleUrls: ["./reprezentanti.component.scss"],
})
export class ReprezentantiComponent implements OnInit {
  constructor(
    private formBuilder: FormBuilder,
    private http: HttpClient,
    public snackBar: MatSnackBar
  ) {}

  ReprezentantForm: FormGroup;
  ReprezentantFormErrors: any;
  @Input() cui;
  Reprezentant;
  Reprezentanti;
  ReprezentantN = true;

  horizontalPosition: MatSnackBarHorizontalPosition = "center";
  verticalPosition: MatSnackBarVerticalPosition = "top";

  ngOnInit() {
    this.getReprezentanti(this.cui);
    this.ReprezentantForm = this.formBuilder.group({
      cnp: [""],
      nume: [""],
      prenume: [""],
      serie_CI: [""],
      numar_CI: [""],
      data_CI: [""],
      eliberat_CI: [""],
      rol: [""],
      telefon: [""],
      adresa: [""],
      firma: [""],
    });
    this.ReprezentantFormErrors = {
      cnp: {},
      nume: {},
      prenume: {},
      serie_CI: {},
      numar_CI: {},
      data_CI: {},
      eliberat_CI: {},
      rol: {},
      telefon: {},
      adresa: {},
    };
    this.ReprezentantN = false;
  }

  // Create a general service for this function
  public formatDateMaterialInput(dateInput: string) {
    if (dateInput) {
      let date = new Date(dateInput);
      const newDate = new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        date.getHours(),
        date.getMinutes() - date.getTimezoneOffset()
      );
      return new Date(newDate).toISOString().split("T")[0];
    }
    return null;
  }

  ActualizeazaReprezentant(reprezentant) {
    reprezentant.data_CI = this.formatDateMaterialInput(reprezentant.data_CI);
    if (this.ReprezentantN == false) {
      this.http
        .put(
          environment.url +
            "/api/amef/reprezentant/" +
            this.Reprezentant.id +
            "/",
          reprezentant
        )
        .subscribe(() => {
          this.snackBar.open("Reprezentant actualizat cu succes!", "Inchide", {
            duration: 1200,
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
          });
          this.getReprezentanti(this.cui);
        });
    } else {
      this.http
        .post(environment.url + "/api/amef/reprezentant/", reprezentant)
        .subscribe(() => {
          this.getReprezentanti(this.cui);
          this.snackBar.open("Reprezentant generat cu succes!", "Inchide", {
            duration: 1200,
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
          });
        });
    }
  }
  ReprezentantNou() {
    this.ReprezentantN = true;

    this.ReprezentantForm.reset();
  }

  StergeReprezentant() {
    if (this.ReprezentantN == false) {
      this.http
        .delete(
          environment.url +
            "/api/amef/reprezentant/" +
            this.Reprezentant.id +
            "/"
        )
        .subscribe(() => {
          this.getReprezentanti(this.cui);
        });
    }
  }

  SetareReprezentant(reprez) {
    this.ReprezentantForm.patchValue(reprez);
    this.Reprezentant = reprez;
    this.ReprezentantN = false;
  }

  getReprezentanti(cui) {
    this.http
      .get(environment.url + "/api/amef/reprezentant/?firma=" + cui)
      .subscribe((Reprezentati: any) => {
        this.Reprezentanti = Reprezentati;
        this.SetareReprezentant(this.Reprezentanti[0]);
      });
  }
}
