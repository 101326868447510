import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';
import { AuthService } from 'app/auth/auth.service';
import { Router} from '@angular/router';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition} from "@angular/material";

@Component({
    selector   : 'fuse-login',
    templateUrl: './login.component.html',
    styleUrls  : ['./login.component.scss'],
    animations : fuseAnimations
})
export class FuseLoginComponent implements OnInit
{
    loginForm: FormGroup;
    loginFormErrors: any;

    constructor(
        private authService: AuthService,
        private fuseConfig: FuseConfigService,
        private formBuilder: FormBuilder,
        public router: Router,
        public snackBar: MatSnackBar
    )
    {
        this.fuseConfig.setConfig({
            layout: {
                navigation: 'none',
                toolbar   : 'none',
                footer    : 'none'
            }
        });

        this.loginFormErrors = {
            username   : {},
            password: {}
        };
    }
    horizontalPosition: MatSnackBarHorizontalPosition = 'center';
    verticalPosition: MatSnackBarVerticalPosition = 'top';

    ngOnInit()
    {
        this.loginForm = this.formBuilder.group({
            username   : ['', Validators.required],
            password: ['', Validators.required]
        });

        this.loginForm.valueChanges.subscribe(() => {
            this.onLoginFormValuesChanged();
        });
    }

    onLoginFormValuesChanged()
    {
        for ( const field in this.loginFormErrors )
        {
            if ( !this.loginFormErrors.hasOwnProperty(field) )
            {
                continue;
            }

            // Clear previous errors
            this.loginFormErrors[field] = {};

            // Get the control
            const control = this.loginForm.get(field);

            if ( control && control.dirty && !control.valid )
            {
                this.loginFormErrors[field] = control.errors;
            }
        }
    }
    login() {
        const val = this.loginForm.value;

        if (val.username && val.password) {
            this.authService.login(val.username, val.password)
                .subscribe(
                    (response) => {
                        this.router.navigateByUrl('/');
                    },
                    (error) => {
                        this.snackBar.open('Eroare logare!', 'Inchide', {
                            duration: 1200,
                            horizontalPosition: this.horizontalPosition,
                            verticalPosition: this.verticalPosition,
                          });
                    }
                );
        }
    }
}
