import { Component, OnInit, Input } from "@angular/core";
import pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from "pdfmake/build/vfs_fonts";
import { DatePipe } from "@angular/common";
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";

@Component({
  selector: "app-service-amef",
  templateUrl: "./service-amef.component.html",
  styleUrls: ["./service-amef.component.scss"],
})
export class ServiceComponent implements OnInit {
  constructor(public datepipe: DatePipe, private formBuilder: FormBuilder) {}
  @Input() amef;
  @Input() Reprezentant;
  RMAForm: FormGroup;
  dateFormat = require("dateformat");
  MentenantaForm: FormGroup;
  ngOnInit() {
    this.RMAForm = this.formBuilder.group({
      motiv: [""],
      mesaj: [""],
      garantie: [""],
      data_defect: this.dateFormat(new Date(), "isoDateTime"),
      data_cerere: this.dateFormat(new Date(), "isoDateTime"),
    });
    this.MentenantaForm = this.formBuilder.group({
      motiv: [""],
      defect_constat: [""],
      garantie: ["NU"],
      observatii_intrare: [""],
      observatii_iesire: [""],
      remediere: [""],
      tehnician: ["MITITELU Tatian"],
      tip: [""],
      data_intrare: this.dateFormat(new Date(), "isoDateTime"),
      data_iesire: this.dateFormat(new Date(), "isoDateTime"),
    });
  }

  GenerareMent(ment) {
    ment.tip = "iesire";
    let dateFormat = require("dateformat");
    const documentDefinition = {
      header: {
        text: "Tekit Web Electronics S.R.L.",
        bold: true,
        alignment: "center",
      },
      content: [
        {
          text: "Fisa de service",
          bold: true,
          alignment: "center",
        },
        {
          border: "solid",
          text: "",
        },
        {
          margin: [0, 20, 0, 0],
          table: {
            // headers are automatically repeated if the table spans over multiple pages
            // you can declare how many rows should be treated as headers
            layout: "lightHorizontalLines",
            headerRows: 1,
            widths: ["auto"],
            body: [
              [
                "Unitatea Acreditată: TEKIT WEB ELECTRONICS S.R.L., cu sediul in Botosani, judet Botosani, localitatea Botosani, inregistrata la Registrul Comertului sub nr. J07/71/2017, CIF: RO37078424, reprezentata prin: legitimat cu 1046, Seria TW, numar 010.",
              ],
            ],
          },
        },
        {
          margin: [0, 20, 0, 0],
          table: {
            // headers are automatically repeated if the table spans over multiple pages
            // you can declare how many rows should be treated as headers
            layout: "lightHorizontalLines",
            headerRows: 1,
            widths: ["auto", "*"],
            body: [
              ["Motivul intrarii in service", ment.motiv],
              ["Observatii intrare", ment.observatii_intrare],
            ],
          },
        },
        {
          margin: [0, 20, 0, 0],
          table: {
            // headers are automatically repeated if the table spans over multiple pages
            // you can declare how many rows should be treated as headers
            layout: "lightHorizontalLines",
            headerRows: 1,
            widths: ["auto", "*"],
            body: [
              ["Datele de identificare AMEF:", this.amef.model.nume],
              ["Serie de Fabricatie", this.amef.serie],
              ["NUI (Numar Unic Identificare)", this.amef.SeriaFiscala],
              ["CUI Client", this.amef.client.cui],
              ["Nume client", this.amef.client.nume],
              ["Echipament in garantie", ment.garantie],
              [
                "Data intrare in service",
                this.datepipe.transform(ment.data_intrare, "dd/MM/yyyy"),
              ],
              ["Telefon", this.amef.client.telefon],
            ],
          },
        },
        {
          margin: [0, 20, 0, 0],
          table: {
            // headers are automatically repeated if the table spans over multiple pages
            // you can declare how many rows should be treated as headers
            layout: "lightHorizontalLines",
            headerRows: 1,
            widths: ["auto", "*"],
            body: [
              ["Defect constatat:", ment.defect_constat],
              ["Observatii iesire", ment.observatii_iesire],
              ["Actiune de remediere", ment.remediere],
              [
                "Data iesire service",
                this.datepipe.transform(ment.data_iesire, "dd/MM/yyyy"),
              ],
            ],
          },
        },
        {
          margin: [0, 20, 0, 0],
          text: "TEHNICIAN DE SERVICE:",
          bold: true,
        },
        {
          table: {
            // headers are automatically repeated if the table spans over multiple pages
            // you can declare how many rows should be treated as headers
            layout: "lightHorizontalLines",
            headerRows: 1,
            widths: ["auto", "*"],
            body: [
              ["Nume si Prenume", ment.tehnician],
              ["Adresa mail", "contact@tekit.ro"],
              ["Numar de telefon", "0745838689"],
            ],
          },
        },
        {
          margin: [0, 20, 0, 0],
          text: "Unitate Acreditata",
          bold: true,
        },
        {
          margin: [0, 20, 0, 0],
          layout: "noBorders",
          table: {
            // headers are automatically repeated if the table spans over multiple pages
            // you can declare how many rows should be treated as headers
            layout: "lightHorizontalLines",
            headerRows: 1,
            widths: ["*", "*"],
            body: [
              ["TEKIT WEB ELECTRONICS SRL", "Data iesire in service:"],
              [
                "Semnatura",
                this.datepipe.transform(ment.data_iesire, "dd/MM/yyyy"),
              ],
            ],
          },
        },
        {
          margin: [0, 20, 0, 0],
          text: "Client",
          bold: true,
        },
        {
          margin: [0, 20, 0, 0],
          layout: "noBorders",
          table: {
            // headers are automatically repeated if the table spans over multiple pages
            // you can declare how many rows should be treated as headers
            layout: "lightHorizontalLines",
            headerRows: 1,
            widths: ["*", "*"],
            body: [[this.amef.client.nume], ["Semnatura"]],
          },
        },
      ],
    };
    pdfMake.createPdf(documentDefinition).open();
  }
  GenerareDeviz(ment) {
    let dateFormat = require("dateformat");
    const documentDefinition = {
      header: {
        text: "Tekit Web Electronics S.R.L.",
        bold: true,
        alignment: "center",
      },
      content: [
        {
          text: "Deviz tehnic",
          bold: true,
          alignment: "center",
        },
        {
          border: "solid",
          text: "",
        },
        {
          margin: [0, 20, 0, 0],
          table: {
            // headers are automatically repeated if the table spans over multiple pages
            // you can declare how many rows should be treated as headers
            layout: "lightHorizontalLines",
            headerRows: 1,
            widths: ["auto"],
            body: [
              [
                "Unitatea Acreditată: TEKIT WEB ELECTRONICS S.R.L., cu sediul in Botosani, judet Botosani, localitatea Botosani, inregistrata la Registrul Comertului sub nr. J07/71/2017, CIF: RO37078424, reprezentata prin: legitimat cu 1046, Seria TW, numar 010.",
              ],
            ],
          },
        },
        {
          margin: [0, 20, 0, 0],
          table: {
            // headers are automatically repeated if the table spans over multiple pages
            // you can declare how many rows should be treated as headers
            layout: "lightHorizontalLines",
            headerRows: 1,
            widths: ["auto", "*"],
            body: [
              ["Motivul intrarii in service", ment.motiv],
              ["Observatii intrare", ment.observatii_intrare],
            ],
          },
        },
        {
          margin: [0, 20, 0, 0],
          table: {
            // headers are automatically repeated if the table spans over multiple pages
            // you can declare how many rows should be treated as headers
            layout: "lightHorizontalLines",
            headerRows: 1,
            widths: ["auto", "*"],
            body: [
              ["Datele de identificare AMEF:", this.amef.model.nume],
              ["Serie de Fabricatie", this.amef.serie],
              ["NUI (Numar Unic Identificare)", this.amef.SeriaFiscala],
              ["CUI Client", this.amef.client.cui],
              ["Nume client", this.amef.client.nume],
              ["Echipament in garantie", ment.garantie],
              [
                "Data intrare in service",
                this.datepipe.transform(ment.data_intrare, "dd/MM/yyyy"),
              ],
              ["Telefon", this.amef.client.telefon],
            ],
          },
        },
        {
          margin: [0, 20, 0, 0],
          text: "TEHNICIAN DE SERVICE:",
          bold: true,
        },
        {
          table: {
            // headers are automatically repeated if the table spans over multiple pages
            // you can declare how many rows should be treated as headers
            layout: "lightHorizontalLines",
            headerRows: 1,
            widths: ["auto", "*"],
            body: [
              ["Nume si Prenume", ment.tehnician],
              ["Adresa mail", "contact@tekit.ro"],
              ["Numar de telefon", "0745838689"],
            ],
          },
        },
        {
          margin: [0, 20, 0, 0],
          text: "Unitate Acreditata",
          bold: true,
        },
        {
          margin: [0, 20, 0, 0],
          layout: "noBorders",
          table: {
            // headers are automatically repeated if the table spans over multiple pages
            // you can declare how many rows should be treated as headers
            layout: "lightHorizontalLines",
            headerRows: 1,
            widths: ["*", "*"],
            body: [
              ["TEKIT WEB ELECTRONICS SRL", "Data intrare in service:"],
              [
                "Semnatura",
                this.datepipe.transform(ment.data_intrare, "dd/MM/yyyy"),
              ],
            ],
          },
        },
        {
          margin: [0, 20, 0, 0],
          text: "Client",
          bold: true,
        },
        {
          margin: [0, 20, 0, 0],
          layout: "noBorders",
          table: {
            // headers are automatically repeated if the table spans over multiple pages
            // you can declare how many rows should be treated as headers
            layout: "lightHorizontalLines",
            headerRows: 1,
            widths: ["*", "*"],
            body: [[this.amef.client.nume], ["Semnatura"]],
          },
        },
      ],
    };
    pdfMake.createPdf(documentDefinition).open();
  }

  getBase64ImageFromURL(url) {
    return new Promise((resolve, reject) => {
      var img = new Image();
      img.setAttribute("crossOrigin", "anonymous");

      img.onload = () => {
        var canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;

        var ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);

        var dataURL = canvas.toDataURL("image/png");

        resolve(dataURL);
      };

      img.onerror = (error) => {
        reject(error);
      };

      img.src = url;
    });
  }

  GenerareRMA(rma) {
    let dateFormat = require("dateformat");
    rma.data_cerere = dateFormat(rma.data_cerere, "MM/DD/YYYY");
    const documentDefinition = {
      header: {
        text: "Tekit Web Electronics S.R.L.",
        bold: true,
        alignment: "center",
      },
      content: [
        {
          text: "BILET TRIMITERE in ATELIER SERVICE DANUBIUS EXIM",
          bold: true,
          alignment: "center",
        },
        {
          border: "solid",
          text: "",
        },
        {
          margin: [0, 20, 0, 0],
          table: {
            // headers are automatically repeated if the table spans over multiple pages
            // you can declare how many rows should be treated as headers
            layout: "lightHorizontalLines",
            headerRows: 1,
            widths: ["auto"],
            body: [
              [
                "Unitatea Acreditată: TEKIT WEB ELECTRONICS S.R.L., cu sediul in Botosani, judet Botosani, localitatea Botosani, inregistrata la Registrul Comertului sub nr. J07/71/2017, CIF: RO37078424, reprezentata prin: Mititelu Tatian Costel, legitimat cu 1046, Seria TW, numar 010.",
              ],
            ],
          },
        },
        {
          margin: [0, 20, 0, 0],
          table: {
            // headers are automatically repeated if the table spans over multiple pages
            // you can declare how many rows should be treated as headers
            layout: "lightHorizontalLines",
            headerRows: 1,
            widths: ["auto", "*"],
            body: [
              ["Motivul trimiterii (defect constatat)", rma.motiv],
              ["Mesaj eroare aparut pe AMEF", rma.mesaj],
            ],
          },
        },
        {
          margin: [0, 20, 0, 0],
          table: {
            // headers are automatically repeated if the table spans over multiple pages
            // you can declare how many rows should be treated as headers
            layout: "lightHorizontalLines",
            headerRows: 1,
            widths: ["auto", "*"],
            body: [
              ["Datele de identificare AMEF:", this.amef.model.nume],
              ["Serie de Fabricatie", this.amef.serie],
              ["NUI (Numar Unic Identificare)", this.amef.SeriaFiscala],
              ["Platitor/Neplatitor TVA", "Da"],
              ["Echipament in garantie", rma.garantie],
              ["Data la care s-a constatat defectul", rma.data_defect],
            ],
          },
        },
        {
          text: "TEHNICIAN DE SERVICE:",
          bold: true,
        },
        {
          table: {
            // headers are automatically repeated if the table spans over multiple pages
            // you can declare how many rows should be treated as headers
            layout: "lightHorizontalLines",
            headerRows: 1,
            widths: ["auto", "*"],
            body: [
              ["Nume si Prenume", "Mititelu Tatian-Costel"],
              ["Adresa mail", "contact@tekit.ro"],
              ["Numar de telefon", "0745838689"],
            ],
          },
        },
        {
          margin: [0, 20, 0, 0],
          text: "Unitate Acreditata",
          bold: true,
        },
        {
          margin: [0, 20, 0, 0],
          layout: "noBorders",
          table: {
            // headers are automatically repeated if the table spans over multiple pages
            // you can declare how many rows should be treated as headers
            layout: "lightHorizontalLines",
            headerRows: 1,
            widths: ["*", "*"],
            body: [
              ["Semnatura", "Data Cererii:"],
              [" ", rma.data_cerere],
            ],
          },
        },
      ],
    };
    pdfMake.createPdf(documentDefinition).open();
  }
}
