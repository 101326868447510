// src/app/auth/auth.service.ts
import { Injectable } from "@angular/core";
import { JwtHelperService } from "@auth0/angular-jwt";
import { HttpClient } from "@angular/common/http";
import "rxjs/add/operator/do";
import "rxjs/add/operator/shareReplay";
import { environment } from "../../environments/environment";
@Injectable()
export class AuthService {
  constructor(public jwtHelper: JwtHelperService, private http: HttpClient) {}

  login(username: string, password: string) {
    return this.http
      .post(environment.url + "/api-token-auth/", { username, password })
      .do((data) => this.setSession(data))
      .shareReplay();
  }
  private setSession(authResult) {
    localStorage.setItem("token", authResult.token);
  }

  public isAuthenticated(): boolean {
    const token = localStorage.getItem("token");
    // Check whether the token is expired and return
    // true or false
    return !this.jwtHelper.isTokenExpired(token);
  }
  logout() {
    localStorage.removeItem("token");
  }
}
