import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, RouterModule, Router } from "@angular/router";
import { AuthGuardService as AuthGuard } from "app/auth/auth-guard.service";
import { HttpClient } from "@angular/common/http";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { environment } from "../../../../../environments/environment";
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from "@angular/material";

@Component({
  selector: "app-amef-vanzare",
  templateUrl: "./vanzare.component.html",
  styleUrls: ["./vanzare.component.scss"],
})
export class AmefVanzareComponent implements OnInit {
  ReprezentantCunoscut = false;
  FirmaCunoscut = false;
  FinalizareCheck = false;
  CAN = false;
  Reprezentanti;
  CANLink = "";
  Telefon;
  PunctLucruCunoscut = false;
  PunctLucruCunoscut2 = false;
  seria;
  adresa: any;
  Client;
  PunctLucru;
  Reprezentant;
  AMEF;
  Factura = {
    companyVatCode: null,
    Serie: null,
    PretCasa: null,
    PretService: 10,
    PerioadaService: null,
    TipContract: "Basic",
    Nume: null,
    TotalService: null,
  };
  ClientForm: FormGroup;
  PunctDeLucruForm: FormGroup;
  ReprezentantForm: FormGroup;
  horizontalStepperStep1Errors: any;
  horizontalStepperStep2Errors: any;
  horizontalStepperStep3Errors: any;
  horizontalPosition: MatSnackBarHorizontalPosition = "center";
  verticalPosition: MatSnackBarVerticalPosition = "top";

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    private formBuilder: FormBuilder,
    public snackBar: MatSnackBar
  ) {
    this.horizontalStepperStep1Errors = {
      cui: {},
      nume: {},
      adresa: {},
      nr_inregistrare: {},
    };

    this.horizontalStepperStep2Errors = {
      adresa: {},
    };

    this.horizontalStepperStep3Errors = {
      cnp: {},
      nume: {},
      prenume: {},
      serie_CI: {},
      numar_CI: {},
      data_CI: {},
      eliberat_CI: {},
      rol: {},
      telefon: {},
      adresa: {},
    };
  }
  ngOnInit() {
    this.seria = this.route.snapshot.params["seria"];

    this.ClientForm = this.formBuilder.group({
      cui: ["", Validators.required],
      nume: ["", Validators.required],
      adresa: ["", Validators.required],
      nr_inregistrare: ["", Validators.required],
      telefon: [""],
    });

    this.PunctDeLucruForm = this.formBuilder.group({
      adresa: ["", Validators.required],
    });

    this.ReprezentantForm = this.formBuilder.group({
      cnp: [""],
      nume: [""],
      prenume: [""],
      serie_CI: [""],
      numar_CI: [""],
      data_CI: [""],
      eliberat_CI: [""],
      rol: [""],
      telefon: [""],
      adresa: [""],
    });
    this.ReprezentantForm.patchValue({
      eliberat_CI: "SPCLEP Botosani",
      rol: "Administrator",
      serie_CI: "XT",
    });
    this.getAMEF(this.seria);
  }
  getAMEF(serie) {
    this.http
      .get(environment.url + "/api/amef/amef/" + serie + "/")
      .subscribe((AM: any) => {
        this.AMEF = AM;
        this.Factura.PretCasa = AM.model.pret;
      });
  }

  VerificaFirma() {
    this.http
      .get(
        environment.url + "/api/amef/clienti/" + this.ClientForm.value.cui + "/"
      )
      .subscribe(
        (Client: any) => {
          this.FirmaCunoscut = true;
          this.Client = Client;
          if (this.Client) {
            this.ClientForm.setValue({
              cui: this.Client.cui,
              nume: this.Client.nume,
              nr_inregistrare: this.Client.nr_inregistrare,
              adresa: this.Client.adresa,
              telefon: this.Client.telefon,
            });
            if (Client.punct_de_lucru) {
              this.PunctLucruCunoscut = true;
            }
            if (Client.reprezentant) {
              this.http
                .get(
                  environment.url +
                    "/api/amef/reprezentant/?firma=" +
                    this.Client.cui
                )
                .subscribe((Reprezentati: any) => {
                  this.Reprezentanti = Reprezentati;
                });
            }
          }
        },
        (error) => {
          var cui = this.ClientForm.value.cui.split("RO", 2);
          if (cui.length == 2) {
            cui.shift();
          }
          this.http
            .get("http://tva.tekit.ro/client.php?cui=" + cui[0])
            .subscribe((datetva1: any) => {
              this.ClientForm.setValue({
                cui: this.ClientForm.value.cui,
                nume: datetva1.denumire,
                nr_inregistrare: datetva1.onrc,
                adresa: datetva1.adresa,
                telefon: datetva1.telefon,
              });
            });
        }
      );
  }
  SetarePL(adresanou) {
    this.PunctDeLucruForm.setValue({ adresa: adresanou.adresa });
    this.PunctLucru = adresanou;
    this.PunctLucruCunoscut2 = true;
  }

  /*
	VerificaAMEF(){
		this.http.get(environment.url+'/api/amef/amef/'+ this.seria +'/')
		.subscribe((AM: any) => {
			this.AMEF = AM;
			if(this.Client){
				this.ClientCunoscut = true;
				this.ClientForm.setValue(
					{cui:this.Client.cui, 
						nume:this.Client.nume,
						nr_inregistrare: this.Client.nr_inregistrare,
						adresa: this.Client.adresa, 
						telefon: this.Client.telefon});
			}
		},
		error => {
			
		}
		);
	} */
  TrimiteFirma() {
    this.http
      .post(environment.url + "/api/amef/clienti/", this.ClientForm.value)
      .subscribe((Client: any) => {
        this.Client = Client;
        this.FirmaCunoscut = true;
        this.snackBar.open("Clientul a fost inregistrat!", "Inchide", {
          duration: 700,
          horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,
        });

        //!!!!!
      });
  }
  Ambulant() {
    this.PunctDeLucruForm.setValue({
      adresa: "Comert/Servicii prestate in regim ambulant",
    });
  }
  TrimitePL() {
    this.PunctDeLucruForm.value.firma = this.Client.cui;
    this.http
      .post(
        environment.url + "/api/amef/punctlucru/",
        this.PunctDeLucruForm.value
      )
      .subscribe((PunctLucru: any) => {
        this.PunctLucru = PunctLucru;
        this.PunctLucruCunoscut2 = true;
      });
  }

  // Create a general service for this function
  public formatDateMaterialInput(dateInput: string) {
    if (dateInput) {
      let date = new Date(dateInput);
      const newDate = new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        date.getHours(),
        date.getMinutes() - date.getTimezoneOffset()
      );
      return new Date(newDate).toISOString().split("T")[0];
    }
    return null;
  }

  TrimiteReprezentant() {
    this.ReprezentantForm.value.data_CI = this.formatDateMaterialInput(
      this.ReprezentantForm.value.data_CI
    );
    this.ReprezentantForm.value.firma = this.ClientForm.value.cui;
    this.http
      .post(
        environment.url + "/api/amef/reprezentant/",
        this.ReprezentantForm.value
      )
      .subscribe((Reprezentant: any) => {
        this.Reprezentant = Reprezentant;
        this.snackBar.open("Reprezentantul a fost inregistrat!", "Inchide", {
          duration: 700,
          horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,
        });
      });
  }

  /*
	Facturare(){
		this.Factura.companyVatCode=this.AMEF.model.nume;
		this.Factura.Serie = this.AMEF.serie;
		this.Factura.TotalService = this.Factura.PerioadaService * this.Factura.PretService;
		this.http.post('https://ws.smartbill.ro:8183/SBORO/api/invoice', this.Factura)
		.subscribe((Reprezentant:any) => {
			this.Reprezentant = Reprezentant;
		}
		);

	}
	*/
  GenerareCAN() {
    var date = {
      tip: "Cerere atribuire numar ANAF",
      client: this.Client.cui,
      amef: this.AMEF.serie,
      reprezentant: this.Reprezentant.id,
      punct_de_lucru: this.PunctLucru.id,
    };

    this.http
      .post(environment.url + "/api/amef/can/", date)
      .subscribe((OK: any) => {
        this.CANLink = OK.fisier;
        this.CAN = true;
      });
  }
  GenerareService() {
    this.http
      .post(environment.url + "/api/amef/clienti/", this.ClientForm.value)
      .subscribe((Client: any) => {
        this.Client = Client;
        this.ClientForm.value.setValue(Client);
        //!!!!!
      });
  }

  TrimiteAMEF2() {
    this.AMEF.client = this.Client.cui;
    this.AMEF.punct_de_lucru = this.PunctLucru.id;
    let dateFormat = require("dateformat");
    let now = new Date();
    this.AMEF.data_vanzare = dateFormat(now, "isoDateTime");
    this.AMEF.data_vanzare = this.formatDateMaterialInput(
      this.AMEF.data_vanzare
    );
    this.AMEF.model = this.AMEF.model.id;
    this.http
      .put(
        environment.url + "/api/amef/v-amef/" + this.seria.toString() + "/",
        this.AMEF
      )
      .subscribe(() => {
        this.snackBar.open("Operatiune realizata cu succes.", "Inchide", {
          duration: 700,
          horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,
        });
        this.FinalizareCheck = true;
      });
  }
  TrimiteAMEF() {
    this.AMEF.client = this.Client.cui;
    this.AMEF.punct_de_lucru = this.PunctLucru.id;
    let now = new Date();
    this.AMEF.data_vanzare = now;
    this.AMEF.model = this.AMEF.model.id;
    this.http
      .put(
        environment.url + "/api/amef/v-amef/" + this.seria.toString() + "/",
        this.AMEF
      )
      .subscribe(() => {
        this.snackBar.open("Operatiune realizata cu succes.", "Inchide", {
          duration: 700,
          horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,
        });
        this.FinalizareCheck = true;
        this.router.navigate(["amef/"]);
      });
  }
}
