import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import {
  MatButtonModule,
  MatSnackBarModule,
  MatCardModule,
  MatTooltipModule,
  MatListModule,
  MatChipsModule,
  MatNativeDateModule,
  MatDatepickerModule,
  MatStepperModule,
  MatTabsModule,
  MatCheckboxModule,
  MatFormFieldModule,
  MatIconModule,
  MatInputModule,
  MatSelectModule,
  MatSidenavModule,
} from "@angular/material";
import { TranslateModule } from "@ngx-translate/core";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import { FuseSharedModule } from "@fuse/shared.module";
import { AuthGuardService as AuthGuard } from "app/auth/auth-guard.service";
import { F4102Component } from "./f4102.component";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from "@angular/material/core";
import { MomentDateAdapter } from "@angular/material-moment-adapter";
import * as _moment from "moment";
import { HttpClientModule } from "@angular/common/http";
// tslint:disable-next-line:no-duplicate-imports
import { defaultFormat as _rollupMoment } from "moment";
const moment = _rollupMoment || _moment;

export const MY_FORMATS = {
  parse: {
    dateInput: "D/M/Y",
  },
  display: {
    dateInput: "D/M/Y",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "MMMM YYYY",
  },
};

const routes = [
  {
    path: "f4102",
    component: F4102Component,
    canActivate: [AuthGuard],
  },
];

@NgModule({
  declarations: [F4102Component],
  imports: [
    RouterModule.forChild(routes),
    MatSlideToggleModule,
    TranslateModule,
    MatButtonModule,
    HttpClientModule,
    MatTooltipModule,
    MatCardModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatListModule,
    MatChipsModule,
    MatSnackBarModule,
    MatFormFieldModule,
    MatStepperModule,
    MatIconModule,
    MatInputModule,
    MatSelectModule,
    MatSidenavModule,
    MatCheckboxModule,
    MatTabsModule,
    NgxDatatableModule,
    FuseSharedModule,
  ],

  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],

  exports: [F4102Component],
})
export class F4102Module {}
