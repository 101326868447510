import { Component, OnInit } from "@angular/core";
import { AuthGuardService as AuthGuard } from "app/auth/auth-guard.service";
import { HttpClient } from "@angular/common/http";
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from "@angular/forms";
import { environment } from "../../../../../environments/environment";
@Component({
  selector: "app-producatori",
  templateUrl: "./producatori.component.html",
  styleUrls: ["./producatori.component.scss"],
})
export class ProducatoriComponent implements OnInit {
  rows: any[];
  rows2: any[];
  form: FormGroup;
  producatoriMethod = false;
  modeleMethod = false;
  form2: FormGroup;
  formErrors: any;
  selected = [];
  selectedtab = new FormControl(0);
  loadingIndicator = true;
  reorderable = true;
  constructor(private http: HttpClient, private formBuilder: FormBuilder) {
    this.formErrors = {
      nume: {},
      cui: {},
      nr_inregistrare: {},
      adresa: {},
      banca: {},
      iban: {},
      producator: {},
    };
  }

  ngOnInit() {
    this.getproducatori();
    this.getmodele();

    this.form = this.formBuilder.group({
      id: [""],
      nume: ["", Validators.required],
      cui: ["", Validators.required],
      nr_inregistrare: ["", Validators.required],
      adresa: ["", Validators.required],
      prefix: [""],
      banca: [""],
      iban: [""],
    });

    this.form2 = this.formBuilder.group({
      id: [""],
      nume: ["", Validators.required],
      producator: ["", Validators.required],
      nrautorizatie: ["", Validators.required],
      data_autorizatie: ["", Validators.required],
      pret: [""],
    });

    this.form.valueChanges.subscribe(() => {
      this.onFormValuesChanged();
    });
  }

  getproducatori() {
    this.http
      .get(environment.url + "/api/amef/producatori/")
      .subscribe((producatori: any) => {
        this.rows = producatori;
        this.loadingIndicator = false;
      });
  }

  getmodele() {
    this.http
      .get(environment.url + "/api/amef/modele/")
      .subscribe((modele: any) => {
        this.rows2 = modele;
        this.loadingIndicator = false;
      });
  }
  onSelect({ selected }) {}

  TrimiteProducator(producatornou) {
    if (this.producatoriMethod == true) {
      this.http
        .put(
          environment.url +
            "/api/amef/producatori/" +
            producatornou.id.toString() +
            "/",
          producatornou
        )
        .subscribe(() => {
          this.loadingIndicator = false;
          this.form.reset();
          this.producatoriMethod = false;
          this.rows = [];
          this.selected = [];
          this.getproducatori();
          this.selectedtab.setValue(0);
        });
    } else {
      this.http
        .post(environment.url + "/api/amef/producatori/", producatornou)
        .subscribe(() => {
          this.loadingIndicator = false;
          this.form.reset();
          this.rows = [];
          this.selected = [];
          this.getproducatori();
          this.selectedtab.setValue(0);
        });
    }
  }
  ModificaProducator() {
    this.form.setValue(this.selected[0]);
    this.producatoriMethod = true;
    this.selectedtab.setValue(1);
  }
  StergeProducator() {
    for (var _i = 0; _i < this.selected.length; _i++) {
      this.http
        .delete(
          environment.url +
            "/api/amef/producatori/" +
            this.selected[_i].id +
            "/"
        )
        .subscribe(() => {
          this.loadingIndicator = false;
          this.rows2 = [];
          this.selected = [];
          this.getproducatori();
        });
    }
  }

  TrimiteModel(modelnou) {
    if (this.modeleMethod == true) {
      this.http
        .put(
          environment.url + "/api/amef/modele/" + modelnou.id.toString() + "/",
          modelnou
        )
        .subscribe(() => {
          this.loadingIndicator = false;
          this.form2.reset();
          this.modeleMethod = false;
          this.rows2 = [];
          this.selected = [];
          this.getmodele();
          this.selectedtab.setValue(2);
        });
    } else {
      this.http
        .post(environment.url + "/api/amef/modele/", modelnou)
        .subscribe(() => {
          this.loadingIndicator = false;
          this.form2.reset();
          this.rows2 = [];
          this.selected = [];
          this.getmodele();
          this.selectedtab.setValue(2);
        });
    }
  }

  ModificaModel() {
    this.form2.setValue(this.selected[0]);
    this.modeleMethod = true;
    this.selectedtab.setValue(3);
  }
  StergeModel() {
    for (var _i = 0; _i < this.selected.length; _i++) {
      this.http
        .delete(
          environment.url + "/api/amef/modele/" + this.selected[_i].id + "/"
        )
        .subscribe(() => {
          this.loadingIndicator = false;
          this.rows2 = [];
          this.selected = [];
          this.getmodele();
        });
    }
  }

  onFormValuesChanged() {
    for (const field in this.formErrors) {
      if (!this.formErrors.hasOwnProperty(field)) {
        continue;
      }

      // Clear previous errors
      this.formErrors[field] = {};

      // Get the control
      const control = this.form.get(field);

      if (control && control.dirty && !control.valid) {
        this.formErrors[field] = control.errors;
      }
    }
  }
}
