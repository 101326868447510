import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, RouterModule, Router } from "@angular/router";
import { AuthGuardService as AuthGuard } from "app/auth/auth-guard.service";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../../environments/environment";
import {
  MatSnackBar,
  MatChipsModule,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from "@angular/material";
import { ExcelService } from "../../services/excel.service";
@Component({
  selector: "app-conectare",
  templateUrl: "./conectare.component.html",
  styleUrls: ["./conectare.component.scss"],
})
export class ConectareComponent implements OnInit {
  rows: any[];
  temp = [];
  loadingIndicator = true;
  selected = [];
  horizontalPosition: MatSnackBarHorizontalPosition = "center";
  verticalPosition: MatSnackBarVerticalPosition = "top";
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    public snackBar: MatSnackBar,
    private excelService: ExcelService
  ) {}

  ngOnInit() {
    this.getAMEF();
  }

  monthDiff(dateFrom, dateTo) {
    return (
      dateTo.getMonth() -
      dateFrom.getMonth() +
      12 * (dateTo.getFullYear() - dateFrom.getFullYear())
    );
  }

  PrepareData(list) {
    let now = new Date();
    for (let i = 0; i < list.length; i++) {
      switch (list[i].model_nume) {
        case "DP 25": {
          list[i].pret_conectare = 150;
          break;
        }
        case "DP 25X": {
          list[i].pret_conectare = 150;
          break;
        }
        case "DP 05": {
          list[i].pret_conectare = 150;
          break;
        }
        case "M20": {
          list[i].pret_conectare = 300;
          break;
        }
        case "DP 150": {
          list[i].pret_conectare = 150;
          break;
        }
        case "WP 50": {
          list[i].pret_conectare = 150;
          break;
        }
        case "Partner 600": {
          list[i].pret_conectare = 300;
          break;
        }
        case "Partner 200": {
          list[i].pret_conectare = 300;
          break;
        }
      }
      let endDate = new Date(list[i].sfarsit_service);
      list[i].service_restant = this.monthDiff(now, endDate);
      list[i].service_restant = -1 * list[i].service_restant;
      list[i].discount = list[i].service_restant * list[i].pretservice;
    }
    return list;
  }
  getAMEF() {
    this.http
      .get(environment.url + "/api/amef/s-amef/")
      .subscribe((amef: any) => {
        this.temp = [...amef];
        this.rows = this.PrepareData(amef);
        this.loadingIndicator = false;
        this.rows = [...this.rows];
        console.log(this.rows);
      });
  }

  DetaliiAMEF() {
    this.router.navigate(["amef/detalii", this.selected[0].serie]);
  }
  SeriaUpdateFilter(event) {
    const val = event.target.value.toLowerCase();

    // filter our data
    const temp = this.temp.filter(function (d) {
      return d.serie.toLowerCase().indexOf(val) !== -1 || !val;
    });

    // update the rows
    this.rows = temp;
    // Whenever the filter changes, always go back to the first page
  }

  ConectatUpdateFilter(event) {
    const val = event.value;

    // filter our data
    const temp = this.temp.filter(function (d) {
      if (val == "") {
        return d.furnizorSIM != "neinregistrat";
      } else {
        return d.furnizorSIM == val;
      }
    });

    // update the rows
    this.rows = temp;
    // Whenever the filter changes, always go back to the first page
  }
  ModelUpdateFilter(event) {
    const val = event.target.value.toLowerCase();

    // filter our data
    const temp = this.temp.filter(function (d) {
      return d.model_nume.toLowerCase().indexOf(val) !== -1 || !val;
    });

    // update the rows
    this.rows = temp;
    // Whenever the filter changes, always go back to the first page
  }
  ServiceUpdateFilter(event) {
    const val = event.value.toLowerCase();
    // filter our data
    const temp = this.temp.filter(function (d) {
      return d.service_status.toLowerCase().indexOf(val) !== -1 || !val;
    });
    this.rows = temp;
  }

  FiscalizareUpdateFilter(event) {
    const val = event.value.toLowerCase();

    // filter our data

    const temp = this.temp.filter(function (d) {
      if (d.SeriaFiscala) {
        return d.SeriaFiscala.toLowerCase().indexOf(val) !== -1 || !val; //change here
      }
    });
    this.rows = temp;
  }

  ClientUpdateFilter(event) {
    const val = event.target.value.toLowerCase();

    // filter our data
    const temp = this.temp.filter(function (d) {
      if (d.client_nume) {
        return d.client_nume.toLowerCase().indexOf(val) !== -1 || !val;
      }
    });

    // update the rows
    this.rows = temp;
    // Whenever the filter changes, always go back to the first page
  }

  TehnicianUpdateFilter(event) {
    const val = event.value;

    // filter our data
    const temp = this.temp.filter(function (d) {
      if (val == "") {
        return true;
      } else {
        return d.teh_service == val;
      }
    });

    // update the rows
    this.rows = temp;
    // Whenever the filter changes, always go back to the first page
  }
  exportAsXLSX(): void {
    this.excelService.exportAsExcelFile(this.rows, "Case de marcat");
  }
  //cui, nume, contract service
  //SeriaFiscala, Adresa,
  onSelect({ selected }) {
    this.selected.splice(0, this.selected.length);
    this.selected.push(...selected);
  }
}
