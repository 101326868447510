import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, RouterModule, Router } from "@angular/router";
import { AuthGuardService as AuthGuard } from "app/auth/auth-guard.service";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../../environments/environment";
import {
  MatExpansionModule,
  MatSnackBar,
  MatChipsModule,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from "@angular/material";
import { ExcelService } from "../../services/excel.service";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: "app-service",
  templateUrl: "./service.component.html",
  styleUrls: ["./service.component.scss"],
})
export class ServiceComponent implements OnInit {
  rows: any[];

  temp = [];
  tehnician = 0;
  tipService = 0;
  pret = 10;
  disableSelect = false;
  data_sfarsit;
  loadingIndicator = true;
  dateFormat = require("dateformat");
  selected = [];
  horizontalPosition: MatSnackBarHorizontalPosition = "center";
  verticalPosition: MatSnackBarVerticalPosition = "top";
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    public snackBar: MatSnackBar,
    private excelService: ExcelService
  ) {}

  ngOnInit() {
    this.getAMEF();
  }

  getAMEF() {
    this.http
      .get(environment.url + "/api/amef/service-amef/")
      .subscribe((amef: any) => {
        this.temp = [...amef];
        this.rows = amef;
        this.loadingIndicator = false;
        this.rows = [...this.rows];
      });
  }

  // Create a general service for this function
  public formatDateMaterialInput(dateInput: string) {
    if (dateInput) {
      let date = new Date(dateInput);
      const newDate = new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        date.getHours(),
        date.getMinutes() - date.getTimezoneOffset()
      );
      return new Date(newDate).toISOString().split("T")[0];
    }
    return null;
  }
  GenerareCS() {
    for (var _i = 0; _i < this.selected.length; _i++) {
      var date = {
        pret: this.pret,
        data_sfarsit: this.formatDateMaterialInput(this.data_sfarsit),
        casa: this.selected[_i].serie,
        reprezentant: this.selected[_i].reprezentant_id,
        punct_de_lucru: this.selected[_i].punctlucru_id,
        client: this.selected[_i].client,
      };
      //toate amef -> obtine toate amef pt client
      this.http.post(environment.url + "/api/amef/cservice/", date).subscribe(
        (OK: any) => {
          this.getAMEF();
          this.snackBar.open("Contractele au fost actualizate!", "Inchide", {
            duration: 1200,
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
          });
          this.selected = [];
        },
        (error) => {
          this.snackBar.open(
            "Eroare generare/actualizare contract!",
            "Inchide",
            {
              duration: 1200,
              horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,
            }
          );
        }
      );
    }
  }

  DetaliiAMEF() {
    this.router.navigate(["amef/detalii", this.selected[0].serie]);
  }

  ViewContract() {
    window.open(
      environment.url + "/media/" + this.selected[0].contract_link,
      "_blank"
    );
  }

  SeriaUpdateFilter(event) {
    const val = event.target.value.toLowerCase();

    // filter our data
    const temp = this.temp.filter(function (d) {
      return d.serie.toLowerCase().indexOf(val) !== -1 || !val;
    });

    // update the rows
    this.rows = temp;
    // Whenever the filter changes, always go back to the first page
  }
  ClientUpdateFilter(event) {
    const val = event.target.value.toLowerCase();

    // filter our data
    const temp = this.temp.filter(function (d) {
      if (d.client_nume) {
        return d.client_nume.toLowerCase().indexOf(val) !== -1 || !val;
      }
    });

    // update the rows
    this.rows = temp;
    // Whenever the filter changes, always go back to the first page
  }
  PunctLucruUpdateFilter(event) {
    const val = event.target.value.toLowerCase();

    // filter our data
    const temp = this.temp.filter(function (d) {
      if (d.punctlucru) {
        return d.punctlucru.toLowerCase().indexOf(val) !== -1 || !val;
      }
    });

    // update the rows
    this.rows = temp;
    // Whenever the filter changes, always go back to the first page
  }
  TelefonUpdateFilter(event) {
    const val = event.target.value.toLowerCase();

    // filter our data
    const temp = this.temp.filter(function (d) {
      if (d.telefon) {
        return d.telefon.toLowerCase().indexOf(val) !== -1 || !val;
      }
    });

    // update the rows
    this.rows = temp;
    // Whenever the filter changes, always go back to the first page
  }

  TehnicianServiceUpdateFilter(event) {
    const val = event.value;
    // filter our data
    const temp = this.temp.filter(function (d) {
      if (val != 0) {
        return d.teh_service == val;
      } else {
        return d.teh_service != val;
      }
    });

    // update the rows
    this.rows = temp;
    // Whenever the filter changes, always go back to the first page
  }

  ServiceUpdateFilter(event) {
    const val = event.value;
    var temp;
    if (val == 0) {
      this.disableSelect = false;
      temp = this.temp.filter(function (d) {
        return d.service_status != "toate";
      });
    }
    if (val == 1) {
      this.disableSelect = true;
      temp = this.rows.filter(function (d) {
        return d.service_status.toLowerCase() == "expirat";
      });
    }
    if (val == 2) {
      this.disableSelect = true;
      temp = this.rows.filter(function (d) {
        return d.service_status.toLowerCase() == "valid";
      });
    }
    if (val == 3) {
      this.disableSelect = true;
      temp = this.rows.filter(function (d) {
        return d.service_status.toLowerCase() == "neinitiat";
      });
    }

    // update the rows
    this.rows = temp;
    // Whenever the filter changes, always go back to the first page
  }

  exportAsXLSX(): void {
    this.excelService.exportAsExcelFile(this.rows, "Service");
  }
  generatePdf() {
    var medie = 0;
    var data = [
      [
        { text: "Nr.", bold: true, alignment: "center" },
        { text: "Client", bold: true, alignment: "center" },
        { text: "Contract", bold: true, aligment: "center" },
        { text: "Serie", bold: true, alignment: "center" },
        { text: "Data sfarsit service", bold: true, alignment: "center" },
        { text: "Telefon", bold: true, alignment: "center" },
        { text: "Punct Lucru", bold: true, alignment: "center" },
      ],
    ];
    for (var _i = 0; _i < this.selected.length; _i++) {
      medie = medie + this.selected[_i]["pretservice"];
      if (this.selected[_i]["service_status"] != "Valid") {
        data.push([
          { text: _i + 1, alignment: "center" },
          {
            text: this.selected[_i]["client_nume"],
            bold: true,
            alignment: "center",
          },
          {
            text: this.selected[_i]["service_status"],
            bold: true,
            alignment: "center",
          },
          { text: this.selected[_i]["serie"], alignment: "center" },
          { text: this.selected[_i]["dataservice"], alignment: "center" },
          { text: this.selected[_i]["telefon"], alignment: "center" },
          this.selected[_i]["punctlucru"],
        ]);
      } else {
        data.push([
          { text: _i + 1, alignment: "center" },
          {
            text: this.selected[_i]["client_nume"],
            bold: true,
            alignment: "center",
          },
          { text: this.selected[_i]["service_status"], alignment: "center" },
          { text: this.selected[_i]["serie"], alignment: "center" },
          { text: this.selected[_i]["dataservice"], alignment: "center" },
          { text: this.selected[_i]["telefon"], alignment: "center" },
          this.selected[_i]["punctlucru"],
        ]);
      }
    }

    var tehnician = "Toti";
    if (this.tehnician == 2) {
      tehnician = "Tatian Mititelu";
    }
    if (this.tehnician == 4) {
      tehnician = "Costel Luca";
    }

    const documentDefinition = {
      footer: function (currentPage, pageCount) {
        return {
          text: currentPage.toString() + " din " + pageCount,
          alignment: "center",
        };
      },
      header: {
        text: "Tekit Web Electronics S.R.L.",
        bold: true,
        alignment: "center",
      },

      pageOrientation: "landscape",
      content: [
        {
          text: "Situatie service - " + tehnician,
          bold: true,
          fontSize: 20,
          alignment: "center",
          margin: [0, 0, 0, 20],
        },
        {
          layout: "lightHorizontalLines", // optional
          table: {
            // headers are automatically repeated if the table spans over multiple pages
            // you can declare how many rows should be treated as headers
            headerRows: 1,
            widths: ["auto", "auto", "auto", "auto", "auto", "auto", "auto"],
            body: data,
            margin: [0, 0, 0, 40],
          },
        },
        {
          text:
            "Medie incasari potentiale service: " +
            (medie / this.selected.length).toFixed(2) +
            "lei / luna / casa",
          bold: true,
          fontSize: 10,
          alignment: "left",
          margin: [0, 0, 0, 20],
        },
        {
          text:
            "Medie incasari potentiale service: " +
            medie.toFixed(2) +
            "lei / luna",
          bold: true,
          fontSize: 10,
          alignment: "left",
          margin: [0, 0, 0, 20],
        },
        {
          text: "Numar AMEF: " + this.selected.length,
          bold: true,
          fontSize: 10,
          alignment: "left",
          margin: [0, 0, 0, 20],
        },
      ],
    };
    pdfMake.createPdf(documentDefinition).open();
  }
  //cui, nume, contract service
  //SeriaFiscala, Adresa,
  onSelect({ selected }) {
    this.selected.splice(0, this.selected.length);
    this.selected.push(...selected);
  }
}
