import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { AuthGuardService as AuthGuard } from 'app/auth/auth-guard.service';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { environment } from '../../../../../environments/environment';


@Component({
  selector: 'app-clienti-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.scss']
})
export class ClientiDetailComponent implements OnInit {

  constructor(private route: ActivatedRoute, private http: HttpClient, private formBuilder: FormBuilder) { }


  ClientForm: FormGroup;
  ReprezentantForm: FormGroup;
  PunctDeLucruForm: FormGroup;
  PunctLucru;
  PuncteDeLucru;
  cui;
  adresa: any;
  Client;
  horizontalStepperStep3Errors: any;
  horizontalStepperStep2Errors: any;
  horizontalStepperStep1Errors: any;
  Reprezentanti;
  Reprezentant;
  ReprezentantN = true;
  PLN = true;
  selectedtab = new FormControl(0);
  ngOnInit() {
  	this.cui = this.route.snapshot.params["cui"];
  	this.horizontalStepperStep1Errors = {
      cui: {},
      nume : {},
      adresa : {},
      nr_inregistrare: {}
    };
    this.horizontalStepperStep2Errors = {
      adresa: {}
    };
    this.horizontalStepperStep3Errors = {
      cnp : {},
      nume      : {},
      prenume     : {},
      serie_CI: {},
      numar_CI: {},
      data_CI: {},
      eliberat_CI: {},
      rol: {},
      telefon: {},
      adresa: {}
    };
    this.ClientForm = this.formBuilder.group({
      cui: ['', Validators.required],
      nume : ['', Validators.required],
      adresa : ['', Validators.required],
      nr_inregistrare : ['', Validators.required],
      telefon : ['']
    });
    this.PunctDeLucruForm = this.formBuilder.group({
      adresa: ['', Validators.required]
    });
    this.ReprezentantForm = this.formBuilder.group({
      cnp : [''],
      nume      : [''],
      prenume     : [''],
      serie_CI: [''],
      numar_CI: [''],
      data_CI: [''],
      eliberat_CI: [''],
      rol: [''],
      telefon: [''],
      adresa: [''],
      firma: ['']
    });
    this.getClient(this.cui);
  }
  getClient(cui){

    this.http.get(environment.url+'/api/amef/clienti/'+cui +'/')
    .subscribe((Client: any) => {
      this.Client = Client;
      this.PuncteDeLucru = this.Client.punct_de_lucru;
      this.ClientForm.patchValue(this.Client);
      this.http.get(environment.url+'/api/amef/reprezentant/?firma='+cui)
      .subscribe((Reprezentati: any) => {
        this.Reprezentanti = Reprezentati;
        this.ReprezentantForm.patchValue({firma: cui});
      });
    });


  }
  ActualizeazaClient(client){
    this.http.put(environment.url+'/api/amef/clienti/' + client.cui.toString() +'/', client)
    .subscribe(() => {

    	this.getClient(this.cui);
    	this.ReprezentantForm.patchValue({firma: this.cui});
    });

  }
  SetarePL(adresanou){
    this.PunctDeLucruForm.setValue({adresa: adresanou.adresa});
    this.PunctLucru = adresanou;
    this.PLN=false;
  }
  ActualizeazaPL(adresanou){
    adresanou.firma = this.cui;
    if(this.PLN==false){
      this.http.put(environment.url+'/api/amef/punctlucru/' + this.PunctLucru.id +'/', adresanou)
      .subscribe(() => {
        this.getClient(this.cui);
      });
    }
    else{
      this.http.post(environment.url+'/api/amef/punctlucru/', adresanou)
      .subscribe(() => {

        this.getClient(this.cui);
      });
    }

  }
  
  ActualizeazaReprezentant(reprezentant){
  	reprezentant.firma = this.cui;
  	if(this.ReprezentantN==false){
  		this.http.put(environment.url+'/api/amef/reprezentant/' + this.Reprezentant.id +'/', reprezentant)
  		.subscribe(() => {
  			this.getClient(this.cui);
  		});
  	}
  	else{
  		this.http.post(environment.url+'/api/amef/reprezentant/', reprezentant)
  		.subscribe(() => {

  			this.getClient(this.cui);
  		});
  	}

  }
  VerificaFirma(){

  }
  ReprezentantNou(){
  	this.ReprezentantN = true;
  	
  	this.ReprezentantForm.reset();
  }
  PLNou(){
    this.PLN = true;
    
    this.PunctDeLucruForm.reset();
  }

  SetareReprezentant(reprez)
  {
  	this.ReprezentantForm.patchValue(reprez);
  	this.Reprezentant = reprez;
  	this.ReprezentantN = false;
  }
  StergeReprezentant(){
  	if(this.ReprezentantN == false){
  		this.http.delete(environment.url+'/api/amef/reprezentant/' + this.Reprezentant.id + '/')
  		.subscribe(() => {
  			this.getClient(this.cui);
  		});
  	}

  }
  StergePL(){
    if(this.PLN == false){
      this.http.delete(environment.url+'/api/amef/punctlucru/' + this.PunctLucru.id + '/')
      .subscribe(() => {
        this.getClient(this.cui);
        this.PunctDeLucruForm.reset();
      });
    }

  }

}
