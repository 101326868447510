import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatButtonModule, MatTabsModule, MatCheckboxModule, MatDatepickerModule, MatFormFieldModule, MatIconModule, MatInputModule, MatSelectModule, MatSidenavModule } from '@angular/material';
import { TranslateModule } from '@ngx-translate/core';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { FuseSharedModule } from '@fuse/shared.module';
import { AuthGuardService as AuthGuard } from 'app/auth/auth-guard.service';
import { ProducatoriComponent } from './producatori.component';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';

const routes = [
    {
        path     : 'producatori',
        component: ProducatoriComponent,
        canActivate: [AuthGuard]
    }
];

@NgModule({
    declarations: [
        ProducatoriComponent
    ],
    imports     : [
        RouterModule.forChild(routes),
        MatSlideToggleModule,
        TranslateModule,
        MatButtonModule,
        MatFormFieldModule,
        MatDatepickerModule,
        MatIconModule,
        MatInputModule,
        MatSelectModule,
        MatSidenavModule,
        MatCheckboxModule,
        MatTabsModule,
        NgxDatatableModule,
        FuseSharedModule
    ],
    exports     : [
        ProducatoriComponent
    ]
})

export class ProducatoriModule
{
}
