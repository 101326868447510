import { Component, OnInit } from "@angular/core";
import { AuthGuardService as AuthGuard } from "app/auth/auth-guard.service";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../../environments/environment";
import { ExcelService } from "../../services/excel.service";
@Component({
  selector: "app-contacte",
  templateUrl: "./contacte.component.html",
  styleUrls: ["./contacte.component.scss"],
})
export class ContacteComponent implements OnInit {
  rows: any[];
  temp = [];
  loadingIndicator = true;
  selected = [];
  constructor(private http: HttpClient, private excelService: ExcelService) {}

  ngOnInit() {
    this.getReprezentanti();
  }

  getReprezentanti() {
    this.http
      .get(environment.url + "/api/amef/reprezentant/")
      .subscribe((reprezentanti: any) => {
        this.temp = [...reprezentanti];
        this.rows = reprezentanti;
        this.loadingIndicator = false;
        this.rows = [...this.rows];
      });
  }

  NumeUpdateFilter(event) {
    const val = event.target.value.toLowerCase();
    const temp = this.temp.filter(function (d) {
      return d.nume.toLowerCase().indexOf(val) !== -1 || !val;
    });
    this.rows = temp;
  }
  PrenumeUpdateFilter(event) {
    const val = event.target.value.toLowerCase();
    const temp = this.temp.filter(function (d) {
      return d.prenume.toLowerCase().indexOf(val) !== -1 || !val;
    });
    this.rows = temp;
  }
  FirmaUpdateFilter(event) {
    const val = event.target.value.toLowerCase();
    const temp = this.temp.filter(function (d) {
      return d.firma_nume.toLowerCase().indexOf(val) !== -1 || !val;
    });
    this.rows = temp;
  }
  CUIUpdateFilter(event) {
    const val = event.target.value.toLowerCase();
    const temp = this.temp.filter(function (d) {
      return d.firma.toLowerCase().indexOf(val) !== -1 || !val;
    });
    this.rows = temp;
  }
  TelefonUpdateFilter(event) {
    const val = event.target.value.toLowerCase();
    const temp = this.temp.filter(function (d) {
      return d.telefon.toLowerCase().indexOf(val) !== -1 || !val;
    });
    this.rows = temp;
  }
  exportAsXLSX(): void {
    this.excelService.exportAsExcelFile(this.rows, "Contacte");
  }
}
