import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import {
  MatButtonModule,
  MatSnackBarModule,
  MatCardModule,
  MatTooltipModule,
  MatListModule,
  MatChipsModule,
  MatNativeDateModule,
  MatDatepickerModule,
  MatStepperModule,
  MatTabsModule,
  MatCheckboxModule,
  MatFormFieldModule,
  MatIconModule,
  MatInputModule,
  MatSelectModule,
  MatSidenavModule,
} from "@angular/material";
import { TranslateModule } from "@ngx-translate/core";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import { FuseSharedModule } from "@fuse/shared.module";
import { AuthGuardService as AuthGuard } from "app/auth/auth-guard.service";
import { AmefComponent } from "./amef.component";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { AmefDetailComponent } from "./detail/detail.component";
import { AmefVanzareComponent } from "./vanzare/vanzare.component";
import { AdaugareAMEFComponent } from "./adaugare-amef/adaugare-amef.component";
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from "@angular/material/core";
import { MomentDateAdapter } from "@angular/material-moment-adapter";
import * as _moment from "moment";
import { HttpClientModule } from "@angular/common/http";
// tslint:disable-next-line:no-duplicate-imports
import { defaultFormat as _rollupMoment } from "moment";
import { ServiceComponent } from "./detail/service-amef/service-amef.component";
import { DocumenteAmefComponent } from "./detail/documente-amef/documente-amef.component";
import { ReprezentantiComponent } from './detail/reprezentanti/reprezentanti.component';
const moment = _rollupMoment || _moment;

export const MY_FORMATS = {
  parse: {
    dateInput: "D/M/Y",
  },
  display: {
    dateInput: "D/M/Y",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "MMMM YYYY",
  },
};

const routes = [
  {
    path: "amef",
    component: AmefComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "amef/detalii/:seria",
    component: AmefDetailComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "amef/vanzare/:seria",
    component: AmefVanzareComponent,
    canActivate: [AuthGuard],
  },

  {
    path: "amef/adaugare",
    component: AdaugareAMEFComponent,
    canActivate: [AuthGuard],
  },
];

@NgModule({
  declarations: [
    AmefComponent,
    AmefDetailComponent,
    AmefVanzareComponent,
    AdaugareAMEFComponent,
    ServiceComponent,
    DocumenteAmefComponent,
    ReprezentantiComponent,
  ],
  imports: [
    RouterModule.forChild(routes),
    MatSlideToggleModule,
    TranslateModule,
    MatButtonModule,
    HttpClientModule,
    MatTooltipModule,
    MatCardModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatListModule,
    MatChipsModule,
    MatSnackBarModule,
    MatFormFieldModule,
    MatStepperModule,
    MatIconModule,
    MatInputModule,
    MatSelectModule,
    MatSidenavModule,
    MatCheckboxModule,
    MatTabsModule,
    NgxDatatableModule,
    FuseSharedModule,
  ],

  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],

  exports: [AmefComponent],
})
export class AmefModule {}
