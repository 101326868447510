import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import {
  MatButtonModule,
  MatSnackBarModule,
  MatCardModule,
  MatTooltipModule,
  MatListModule,
  MatChipsModule,
  MatNativeDateModule,
  MatDatepickerModule,
  MatStepperModule,
  MatTabsModule,
  MatCheckboxModule,
  MatFormFieldModule,
  MatIconModule,
  MatInputModule,
  MatSelectModule,
  MatSidenavModule,
} from "@angular/material";
import { TranslateModule } from "@ngx-translate/core";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import { FuseSharedModule } from "@fuse/shared.module";
import { AuthGuardService as AuthGuard } from "app/auth/auth-guard.service";
import { ConectareComponent } from "./conectare.component";
import { HttpClientModule } from "@angular/common/http";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";

const routes = [
  {
    path: "conectare",
    component: ConectareComponent,
    canActivate: [AuthGuard],
  },
];

@NgModule({
  declarations: [ConectareComponent],
  imports: [
    RouterModule.forChild(routes),
    MatSlideToggleModule,
    TranslateModule,
    MatButtonModule,
    HttpClientModule,
    MatTooltipModule,
    MatCardModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatListModule,
    MatChipsModule,
    MatSnackBarModule,
    MatFormFieldModule,
    MatStepperModule,
    MatIconModule,
    MatInputModule,
    MatSelectModule,
    MatSidenavModule,
    MatCheckboxModule,
    MatTabsModule,
    NgxDatatableModule,
    FuseSharedModule,
  ],
  exports: [ConectareComponent],
})
export class ConectareModule {}
