import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, RouterModule, Router } from "@angular/router";
import { AuthGuardService as AuthGuard } from "app/auth/auth-guard.service";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../../environments/environment";
import {
  MatSnackBar,
  MatChipsModule,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from "@angular/material";
import { ExcelService } from "../../services/excel.service";
import { type } from "os";
@Component({
  selector: "app-amef",
  templateUrl: "./amef.component.html",
  styleUrls: ["./amef.component.scss"],
})
export class AmefComponent implements OnInit {
  rows: any[];
  temp = [];
  loadingIndicator = true;
  selected = [];
  horizontalPosition: MatSnackBarHorizontalPosition = "center";
  verticalPosition: MatSnackBarVerticalPosition = "top";
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    public snackBar: MatSnackBar,
    private excelService: ExcelService
  ) {}

  ngOnInit() {
    this.getAMEF();
  }

  getAMEF() {
    this.http
      .get(environment.url + "/api/amef/s-amef/")
      .subscribe((amef: any) => {
        this.temp = [...amef];
        this.rows = amef;
        this.loadingIndicator = false;
        this.rows = [...this.rows];
      });
  }

  VanzareAMEF() {
    if (this.selected.length == 1) {
      this.router.navigate(["amef/vanzare", this.selected[0].serie]);
    } else {
      this.router.navigate(["amef/vanzarem/", this.selected[0].serie]);
    }
  }

  DetaliiAMEF() {
    this.router.navigate(["amef/detalii", this.selected[0].serie]);
  }

  // de vazut
  ResetAMEF() {
    for (var _i = 0; _i < 1; _i++) {
      var newamef = {
        serie: this.selected[_i].serie,
        model: this.selected[_i].model,
      };
      this.http
        .delete(
          environment.url + "/api/amef/amef/" + this.selected[_i].serie + "/"
        )
        .subscribe(() => {
          this.http
            .post(environment.url + "/api/amef/v-amef/", newamef)
            .subscribe(() => {
              this.snackBar.open("Resetare realizata cu succes.", "Inchide", {
                duration: 700,
                horizontalPosition: this.horizontalPosition,
                verticalPosition: this.verticalPosition,
              });
              this.getAMEF();
            });
        });
    }
    this.selected = [];
    this.snackBar.open("AMEF au fost resetate!", "Inchide", {
      duration: 700,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
    });
  }

  F4102() {
    var data = [];
    for (var _j = 0; _j < this.selected.length; _j++) {
      data.push({
        serie: this.selected[_j]["serie"],
        model: this.selected[_j]["model"],
        f4102: !this.selected[_j]["f4102"],
      });
    }
    for (var _j = 0; _j < this.selected.length; _j++) {
      this.http
        .put(
          environment.url + "/api/amef/v-amef/" + this.selected[_j].serie + "/",
          data[_j]
        )
        .subscribe(() => {});
    }
    this.getAMEF();
    this.selected = [];
  }

  StergeAMEF() {
    for (var _i = 0; _i < this.selected.length; _i++) {
      this.http
        .delete(
          environment.url + "/api/amef/amef/" + this.selected[_i].serie + "/"
        )
        .subscribe(() => {
          this.loadingIndicator = false;
          this.getAMEF();
        });
    }
    this.selected = [];
    this.snackBar.open("AMEF au fost sterse!", "Inchide", {
      duration: 700,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
    });
  }
  SeriaUpdateFilter(event) {
    const val = event.target.value.toLowerCase();

    // filter our data
    const temp = this.temp.filter(function (d) {
      return d.serie.toLowerCase().indexOf(val) !== -1 || !val;
    });

    // update the rows
    this.rows = temp;
    // Whenever the filter changes, always go back to the first page
  }

  NUIUpdateFilter(event) {
    const val = event.target.value.toLowerCase();

    // filter our data

    const temp = this.temp.filter(function (d) {
      if (d.SeriaFiscala) {
        return d.SeriaFiscala.toLowerCase().indexOf(val) !== -1 || !val;
      }
    });

    // update the rows
    this.rows = temp;
    // Whenever the filter changes, always go back to the first page
  }
  ModelUpdateFilter(event) {
    const val = event.target.value.toLowerCase();

    // filter our data
    const temp = this.temp.filter(function (d) {
      return d.model_nume.toLowerCase().indexOf(val) !== -1 || !val;
    });

    // update the rows
    this.rows = temp;
    // Whenever the filter changes, always go back to the first page
  }
  ServiceUpdateFilter(event) {
    const val = event.value.toLowerCase();
    // filter our data
    const temp = this.temp.filter(function (d) {
      return d.service_status.toLowerCase().indexOf(val) !== -1 || !val;
    });
    this.rows = temp;
  }

  FiscalizareUpdateFilter(event) {
    const val = event.value.toLowerCase();

    // filter our data

    const temp = this.temp.filter(function (d) {
      if (d.SeriaFiscala) {
        return d.SeriaFiscala.toLowerCase().indexOf(val) !== -1 || !val; //change here
      }
    });
    this.rows = temp;
  }

  F4102UpdateFilter(event) {
    const val = event.value;
    // filter our data
    const temp = this.temp.filter(function (d) {
      if (val == "true") {
        return d.f4102;
      }
      if (val == "") {
        return true;
      }
      if (val == "false") {
        return !d.f4102;
      }
    });
    this.rows = temp;
  }

  ClientUpdateFilter(event) {
    const val = event.target.value.toLowerCase();

    // filter our data
    const temp = this.temp.filter(function (d) {
      if (d.client_nume) {
        return d.client_nume.toLowerCase().indexOf(val) !== -1 || !val;
      }
    });

    // update the rows
    this.rows = temp;
    // Whenever the filter changes, always go back to the first page
  }

  TehnicianUpdateFilter(event) {
    const val = event.value;

    // filter our data
    const temp = this.temp.filter(function (d) {
      if (val == "") {
        return true;
      } else {
        return d.teh_service == val;
      }
    });

    // update the rows
    this.rows = temp;
    // Whenever the filter changes, always go back to the first page
  }
  exportAsXLSX(): void {
    this.excelService.exportAsExcelFile(this.rows, "Case de marcat");
  }
  //cui, nume, contract service
  //SeriaFiscala, Adresa,
  onSelect({ selected }) {
    this.selected.splice(0, this.selected.length);
    this.selected.push(...selected);
  }
}
