import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { environment } from "../../../../../environments/environment";
import { AuthGuardService as AuthGuard } from "app/auth/auth-guard.service";
import { HttpClient } from "@angular/common/http";
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
  MatDialogRef,
  MatDialogConfig,
} from "@angular/material";
@Component({
  selector: "app-adaugare",
  templateUrl: "./adaugare.component.html",
  styleUrls: ["./adaugare.component.scss"],
})
export class AdaugareComponent implements OnInit {
  constructor(
    private dialogRef: MatDialogRef<AdaugareComponent>,
    private formBuilder: FormBuilder,
    private http: HttpClient,
    public snackBar: MatSnackBar
  ) {}
  ClientForm: FormGroup;
  horizontalStepperStep1Errors: any;
  horizontalPosition: MatSnackBarHorizontalPosition = "center";
  verticalPosition: MatSnackBarVerticalPosition = "top";
  ngOnInit() {
    this.horizontalStepperStep1Errors = {
      cui: {},
      nume: {},
      adresa: {},
      nr_inregistrare: {},
    };

    this.ClientForm = this.formBuilder.group({
      cui: ["", Validators.required],
      nume: ["", Validators.required],
      adresa: ["", Validators.required],
      nr_inregistrare: ["", Validators.required],
      telefon: [""],
    });
  }

  save() {
    this.http
      .post(environment.url + "/api/amef/clienti/", this.ClientForm.value)
      .subscribe((Client: any) => {});
    this.dialogRef.close();
  }

  close() {
    this.dialogRef.close();
  }

  VerificaFirma() {
    this.http
      .get(
        environment.url + "/api/amef/clienti/" + this.ClientForm.value.cui + "/"
      )
      .subscribe(
        (Client: any) => {
          this.snackBar.open("Clientul exista deja!", "Inchide", {
            duration: 700,
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
          });
        },
        (error) => {
          this.http
            .get(
              environment.url + "/api/firme/" + this.ClientForm.value.cui + "/"
            )
            .subscribe(
              (Firma: any) => {
                this.http
                  .post(environment.url + "/api/amef/clienti/", Firma)
                  .subscribe((Client: any) => {});
              },
              (error) => {
                this.snackBar.open("Clientul nu a fost gasit!", "Inchide", {
                  duration: 700,
                  horizontalPosition: this.horizontalPosition,
                  verticalPosition: this.verticalPosition,
                });
              }
            );
        }
      );
  }
}
