export const navigation = [
    {
        'id'      : 'applications',
        'title'   : 'Aplicatii',
        'translate': 'NAV.APPLICATIONS',
        'type'    : 'group',
        'children': [
            {
                'id'   : 'producatori',
                'title': 'Producatori',
                'type' : 'item',
                'icon' : 'contact_phone',
                'url'  : '/producatori'
            },
            {
                'id'   : 'clienti',
                'title': 'Clienti',
                'type' : 'item',
                'icon' : 'person',
                'url'  : '/clienti'
            },
            {
                'id'   : 'amef',
                'title': 'Amef',
                'type' : 'collapse',
                'icon' : 'desktop_mac',
                'url'  : '/amef',
                'children' : [
                    {
                        'id'        : 'products',
                        'title'     : 'Adaugare AMEF',
                        'type'      : 'item',
                        'url'       : '/amef/adaugare',
                        'exactMatch': true
                    },
                    {
                        'id'        : 'F4102',
                        'title'     : 'F4102',
                        'type'      : 'item',
                        'url'       : '/f4102',
                        'exactMatch': true
                    },
                    
                ]
            },
            {
                        'id'        : 'ContracteServiceAmef',
                        'title'     : 'Contracte Service',
                        'type' : 'collapse',
                        'url'       : '/service',
                        'icon': 'book',
                        'exactMatch': true,
                        'children' : [ 
                            {
                            'id'        : 'prelungire',
                            'title'     : 'Prelungire',
                            'type'      : 'item',
                            'url'       : '/prelungireservice',
                            'exactMatch': true
                            }]
            },
            {
                'id'   : 'Contacte',
                'title': 'Contacte',
                'type' : 'item',
                'icon' : 'account_box',
                'url'  : '/contacte'
            },
            {
                'id'   : 'Conectare',
                'title': 'Conectare',
                'type' : 'item',
                'icon' : 'attach_file',
                'url'  : '/conectare'
            }/*, 
            {
                'id'   : 'Documente',
                'title': 'Documente',
                'type' : 'item',
                'icon' : 'assignment',
                'url'  : '/documente'
            } */
        ]
    }
];
