import { Component, OnInit, Input } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../../../../environments/environment";
import {
  MatDialog,
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from "@angular/material";
import pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from "pdfmake/build/vfs_fonts";
import { DatePipe } from "@angular/common";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: "app-documente-amef",
  templateUrl: "./documente-amef.component.html",
  styleUrls: ["./documente-amef.component.scss"],
})
export class DocumenteAmefComponent implements OnInit {
  constructor(
    public datepipe: DatePipe,
    private http: HttpClient,
    public snackBar: MatSnackBar
  ) {}
  @Input() amef;
  Reprezentant;
  Reprezentanti;

  can;
  listcs: any[];
  cs;
  di;
  pret = 10;
  motiv_extr_mem;
  horizontalPosition: MatSnackBarHorizontalPosition = "center";
  verticalPosition: MatSnackBarVerticalPosition = "top";

  ngOnInit() {
    this.getDocumente(this.amef.serie);
    this.getReprezentanti(this.amef.client.cui);
    if (this.amef.pretservice != null) {
      this.pret = this.amef.pretservice;
    }
  }

  getDocumente(serie) {
    this.getCS(serie);
    this.getCAN(serie);
    this.getDI(serie);
  }
  getDI(serie) {
    this.http
      .get(environment.url + "/api/amef/declInstalare/?amef=" + serie)
      .subscribe((Documente: any) => {
        this.di = Documente[0];
      });
  }
  getCAN(serie) {
    this.http
      .get(environment.url + "/api/amef/can/?amef=" + serie)
      .subscribe((Documente: any) => {
        this.can = Documente[0];
      });
  }
  getCS(serie) {
    this.http
      .get(environment.url + "/api/amef/cservice/?amef=" + serie)
      .subscribe((Documente: any) => {
        this.cs = Documente;
        if (this.amef.pretservice != null) {
          this.pret = this.amef.pretservice;
        }
      });
  }

  StergeCAN() {
    this.http
      .delete(environment.url + "/api/amef/can/" + this.can.id + "/")
      .subscribe(() => {
        this.snackBar.open("Cererea a fost stearsa cu succes!", "Inchide", {
          duration: 1200,
          horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,
        });
        this.getCAN(this.amef.serie);
      });
  }
  StergeDI() {
    this.http
      .delete(environment.url + "/api/amef/declInstalare/" + this.di.id + "/")
      .subscribe(() => {
        this.snackBar.open("Declaratia a fost stearsa cu succes!", "Inchide", {
          duration: 1200,
          horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,
        });
        this.getDI(this.amef.serie);
      });
  }
  StergeCS() {
    this.http
      .delete(environment.url + "/api/amef/cservice/" + this.can.id + "/")
      .subscribe(() => {
        this.snackBar.open("Contractul a fost sters cu succes!", "Inchide", {
          duration: 1200,
          horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,
        });
        this.getCS(this.amef.serie);
      });
  }
  GenerareDI() {
    var date = {
      client: this.amef.client.cui,
      amef: this.amef.serie,
      reprezentant: this.Reprezentant.id,
      punct_de_lucru: this.amef.punct_de_lucru.id,
    };
    if (this.di) {
      this.http
        .put(
          environment.url + "/api/amef/declInstalare/" + this.di.id + "/",
          date
        )
        .subscribe((OK: any) => {
          this.snackBar.open(
            "Declaratia de instalare a fost actualizata cu succes.",
            "Inchide",
            {
              duration: 700,
              horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,
            }
          );
          this.getDI(this.amef.serie);
        });
    } else {
      this.http
        .post(environment.url + "/api/amef/declInstalare/", date)
        .subscribe((OK: any) => {
          this.snackBar.open(
            "Declaratia de instalare a fost generata cu succes.",
            "Inchide",
            {
              duration: 700,
              horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,
            }
          );
          this.getDI(this.amef.serie);
        });
    }
  }

  getReprezentanti(cui) {
    this.http
      .get(environment.url + "/api/amef/reprezentant/?firma=" + cui)
      .subscribe((Reprezentati: any) => {
        this.Reprezentanti = Reprezentati;
        this.Reprezentant = this.Reprezentanti[0];
      });
  }

  // Create a general service for this function
  public formatDateMaterialInput(dateInput: string) {
    if (dateInput) {
      let date = new Date(dateInput);
      const newDate = new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        date.getHours(),
        date.getMinutes() - date.getTimezoneOffset()
      );
      return new Date(newDate).toISOString().split("T")[0];
    }
    return null;
  }

  GenerareCService() {
    if (!this.pret) {
      this.pret = 10;
    }
    var date = {
      pret: this.pret,
      data_sfarsit: this.formatDateMaterialInput(this.amef.sfarsit_service),
      casa: this.amef.serie,
      reprezentant: this.Reprezentant.id,
      punct_de_lucru: this.amef.punct_de_lucru.id,
      client: this.amef.client.cui,
    };

    //toate amef -> obtine toate amef pt client
    this.http.post(environment.url + "/api/amef/cservice/", date).subscribe(
      (OK: any) => {
        this.snackBar.open(
          "Contract actualizat/generat cu succes!",
          "Inchide",
          {
            duration: 1200,
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
          }
        );
        this.getCS(this.amef.serie);
      },
      (error) => {
        this.snackBar.open("Eroare generare/actualizare contract!", "Inchide", {
          duration: 1200,
          horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,
        });
      }
    );
  }
  GenerareCAN() {
    var date = {
      tip: "Cerere atribuire numar ANAF",
      client: this.amef.client.cui,
      amef: this.amef.serie,
      reprezentant: this.Reprezentant.id,
      punct_de_lucru: this.amef.punct_de_lucru.id,
    };
    if (this.can) {
      this.http
        .put(environment.url + "/api/amef/can/" + this.can.id + "/", date)
        .subscribe((OK: any) => {
          this.snackBar.open("Cerere actualizata cu succes!", "Inchide", {
            duration: 1200,
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
          });

          this.getCAN(this.amef.serie);
        });
    } else {
      this.http
        .post(environment.url + "/api/amef/can/", date)
        .subscribe((OK: any) => {
          this.snackBar.open("Cerere generata cu succes!", "Inchide", {
            duration: 1200,
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
          });

          this.getCAN(this.amef.serie);
        });
    }
  }

  async GenerarePVExtragere(motiv) {
    let now = new Date();
    let dateFormat = require("dateformat");
    var producator;
    switch (this.amef.model.producator) {
      case 1: {
        producator = "Datecs";
        break;
      }
      case 2: {
        producator = "Partner";
        break;
      }
      case 3: {
        producator = "Valmed";
        break;
      }
      default: {
        producator = "";
        break;
      }
    }
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    const documentDefinition = {
      header: {
        image: await this.getBase64ImageFromURL("/assets/images/antet.png"),
        fit: [500, 500],
        alignment: "center",
        margin: [0, 20, 0, 20],
      },
      content: [
        {
          text: "PROCES - VERBAL \n DE EXTRAGERE A MEMORIEI FISCALE A SD CARD-LUI SI A FISIERELOR ELECTRONICE .MF SI .JE \n CAT SI DE INSTRUIREA UTILIZATORULUI IN PASTRAREA SI ARHIVAREA ACESTORA\n\n",
          bold: true,
          alignment: "center",
          fontSize: 11,
          margin: [0, 60, 0, 0],
        },
        {
          text: ["DATA: ", this.datepipe.transform(now, "dd.MM.yyyy"), "\n\n"],
          bold: true,
          alignment: "center",
          fontSize: 11,
        },
        {
          text: "In conformitate cu prevederile Art.4(9) din Ordonanata de urgenta nr. 91/2014 pentru modificarea si completarea OUG 28/1999 privind obligatatia operatorilor economici de a utiliza aparate de marcat electronice fiscale, publicata in M.Of. nr. 966/30.12.2014, partile:\n\n",
          alignment: "justify",
          fontSize: 11,
        },
        {
          text: "",
        },
        {
          text: [
            {
              text: "S.C. TEKIT WEB ELECTRONICS SRL",
              bold: true,
            },
            ", cu sediul social in Mun. Botosani, Str. Alexandru Donici, nr.2, sc.B, ap.7, inregistrata la Oficiul Registrului Comertului Botosani, sub nr. J7/71/21.02.2017,",
            {
              text: " C.I.F. RO37078424",
              bold: true,
            },
            ", avand punctul de lucru in Mun. Botosani, Bld Mihai Eminescu nr. 8, Jud. Botosani, in calitate de unitate acreditata pentru service la aparate de marcat electronice fiscale conform Art.1(5) din OUG 91/2014.\n\n",
          ],
          alignment: "justify",
          fontSize: 11,
        },
        {
          text: [
            {
              text: this.amef.client.nume,
              bold: true,
            },
            " cu sediul social in ",
            {
              text: this.amef.client.adresa,
              bold: true,
            },
            ", CUI /CIF ",
            {
              text: this.amef.client.cui,
              bold: true,
            },
            " reprezentata de ",
            {
              text: this.Reprezentant.nume + " " + this.Reprezentant.prenume,
              bold: true,
            },
            ", posesor al BI/CI: seria : " +
              this.Reprezentant.serie_CI +
              " nr. " +
              this.Reprezentant.numar_CI +
              " eliberat(ă) la data de " +
              this.datepipe.transform(this.Reprezentant.data_CI, "dd/MM/yyyy") +
              ", de " +
              this.Reprezentant.eliberat_CI +
              ", in calitate de utilizator al aparatului de marcat electronic fiscal.\n\n",
          ],
          alignment: "justify",
          fontSize: 11,
        },
        {
          text: [
            "Am procedat la extragerea si predarea catre utilizator a memoriei fiscale a aparatului de marcat electronic fiscal Marca ",
            {
              text: producator,
              bold: true,
            },
            ", Model ",
            {
              text: this.amef.model.nume,
              bold: true,
            },
            " , seria AMEF: ",
            {
              text: this.amef.serie,
              bold: true,
            },
            " seria fiscala (NUI): ",
            {
              text: this.amef.SeriaFiscala,
              bold: true,
            },
            ", la Punctul de lucru din ",
            {
              text: this.amef.punct_de_lucru.adresa,
              bold: true,
            },
            ", motivul predarii fiind: ",
            {
              text: motiv,
              bold: true,
            },
            ".\n\n",
          ],
          alignment: "justify",
          fontSize: 11,
        },
        {
          text: "Utilizatorul a luat la cunostinta despre obligativitatea de pastrare si arhivare a memoriei fiscale pe o perioada de 10 ani conform art.4(9) in OUG 91/2014:",
          alignment: "justify",
          fontSize: 11,
        },
        {
          text: ",, Art.4.(9): Operatorii economici care utilizeaza aparate de marcat electronice fiscale definite la art.3 alin.(1) si (2), la umplerea memoriei fiscale, atunci cand aceasta trebuie inlocuita ca urmare a defectiunii, ori cand nu mai poate fi folosita de catre utilizatori din diverse motive, precum si in cazul in care acestia isi inceteaza activitatea, sunt obligati sa asigure pastrarea si arhivarea memoriilor fiscale pentru o perioada de 10 ani. ,,\n\n",
          italics: true,
          alignment: "justify",
          fontSize: 11,
        },
        {
          text: "Prezentul proces-verbal s-a intocmit in 2(doua) exemplare, din care unul la utilizator si unul la unitatea de service acreditata.",
          alignment: "justify",
          fontSize: 11,
        },
        {
          margin: [0, 20, 0, 0],
          layout: "noBorders",
          table: {
            // headers are automatically repeated if the table spans over multiple pages
            // you can declare how many rows should be treated as headers
            layout: "lightHorizontalLines",
            headerRows: 1,
            widths: ["*", "*"],
            body: [["Unitate acreditata", "Client"]],
          },
        },
        {
          margin: [0, 20, 0, 0],
          layout: "noBorders",
          table: {
            // headers are automatically repeated if the table spans over multiple pages
            // you can declare how many rows should be treated as headers
            layout: "lightHorizontalLines",
            headerRows: 1,
            widths: ["*", "*"],
            fontSize: 11,
            body: [["TEKIT WEB ELECTRONICS SRL", this.amef.client.nume]],
          },
        },
        {
          margin: [0, 20, 0, 0],
          layout: "noBorders",
          table: {
            // headers are automatically repeated if the table spans over multiple pages
            // you can declare how many rows should be treated as headers
            layout: "lightHorizontalLines",
            headerRows: 1,
            fontSize: 10,
            widths: ["*", "*"],
            body: [["Semnatura", "Semnatura"]],
          },
        },
      ],
    };
    pdfMake.createPdf(documentDefinition).open();
  }
  getBase64ImageFromURL(url) {
    return new Promise((resolve, reject) => {
      var img = new Image();
      img.setAttribute("crossOrigin", "anonymous");

      img.onload = () => {
        var canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;

        var ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);

        var dataURL = canvas.toDataURL("image/png");

        resolve(dataURL);
      };

      img.onerror = (error) => {
        reject(error);
      };

      img.src = url;
    });
  }
}
