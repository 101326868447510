import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { HttpClientModule } from "@angular/common/http";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouterModule, Routes } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import "hammerjs";
import { AuthGuardService as AuthGuard } from "app/auth/auth-guard.service";
import { JwtModule } from "@auth0/angular-jwt";
import { AuthService } from "app/auth/auth.service";
import { FuseModule } from "@fuse/fuse.module";
import { FuseSharedModule } from "@fuse/shared.module";

import { fuseConfig } from "./fuse-config";

import { AppComponent } from "./app.component";
import { FuseMainModule } from "./main/main.module";
import { FuseSampleModule } from "./main/content/sample/sample.module";
import { DatePipe } from "@angular/common";
import { LoginModule } from "./main/content/login/login.module";

export function tokenGetter() {
  return localStorage.getItem("token");
}

const appRoutes: Routes = [
  {
    path: "producatori",
    redirectTo: "producatori",
  },
  {
    path: "clienti",
    redirectTo: "clienti",
  },
  {
    path: "amef",
    redirectTo: "amef",
  },
  {
    path: "firme",
    redirectTo: "firme",
  },
  {
    path: "**",
    redirectTo: "amef",
  },
];
@NgModule({
  declarations: [AppComponent],
  providers: [AuthGuard, AuthService, DatePipe],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    RouterModule.forRoot(appRoutes),
    TranslateModule.forRoot(),
    LoginModule,

    // Fuse Main and Shared modules
    FuseModule.forRoot(fuseConfig),
    FuseSharedModule,
    FuseMainModule,
    FuseSampleModule,
    JwtModule.forRoot({
      config: {
        skipWhenExpired: true,
        tokenGetter: tokenGetter,
        whitelistedDomains: [
          "localhost:8000",
          "http://13.59.37.181:8000",
          "https://webservicesp.anaf.ro",
        ],
      },
    }),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
