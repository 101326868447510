import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, RouterModule, Router} from "@angular/router";
import { AuthGuardService as AuthGuard } from 'app/auth/auth-guard.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import {MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition, MatDialog, MatDialogConfig} from '@angular/material';
import { AdaugareComponent } from './adaugare/adaugare.component';
import {ExcelService} from '../../services/excel.service';
@Component({
  selector: 'app-clienti',
  templateUrl: './clienti.component.html',
  styleUrls: ['./clienti.component.scss']
})
export class ClientiComponent implements OnInit {

  rows: any[];
  temp = [];
  loadingIndicator = true;
  selected = [];
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  constructor(private http: HttpClient, private router: Router, public snackBar: MatSnackBar, private dialog: MatDialog, private excelService:ExcelService) { }

  ngOnInit() {
  	this.getClienti();
  }

  getClienti(){
    this.http.get(environment.url+'/api/amef/clienti/')
    .subscribe((clienti: any) => {
      this.temp = [...clienti];
      this.rows = clienti;
      this.loadingIndicator = false;
      this.rows = [...this.rows];
    });
  }
  StergeClient(){
    for(var _i = 0; _i<this.selected.length; _i++){
      this.http.delete(environment.url+'/api/amef/clienti/' + this.selected[_i].cui + '/')
      .subscribe(() => {
        this.loadingIndicator = false;
        this.getClienti();


      });
    }
    this.selected = [];
    this.snackBar.open('Clientul a fost sters!', 'Inchide', {
      duration: 700,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
    });
  }
  DetaliiClient(){
    this.router.navigate(['clienti/', this.selected[0].cui]);
  }
  CUIUpdateFilter(event) {
    const val = event.target.value.toLowerCase();

    // filter our data
    const temp = this.temp.filter(function(d) {
      return d.cui.toLowerCase().indexOf(val) !== -1 || !val;
    });

    // update the rows
    this.rows= temp;
    // Whenever the filter changes, always go back to the first page
  }
  NumeUpdateFilter(event) {
    const val = event.target.value.toLowerCase();

    // filter our data
    const temp = this.temp.filter(function(d) {
      return d.nume.toLowerCase().indexOf(val) !== -1 || !val;
    });

    // update the rows
    this.rows= temp;
    // Whenever the filter changes, always go back to the first page
  }
  TelefonUpdateFilter(event) {
    const val = event.target.value.toLowerCase();

    // filter our data
    const temp = this.temp.filter(function(d) {
      return d.telefon.toLowerCase().indexOf(val) !== -1 || !val;
    });

    // update the rows
    this.rows= temp;
    // Whenever the filter changes, always go back to the first page
  }
  AdaugareClient(){
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    const dialogRef = this.dialog.open(AdaugareComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(
      data => this.getClienti());   
  }
  onSelect({ selected }) {
    this.selected.splice(0, this.selected.length);
    this.selected.push(...selected);
  }
  exportAsXLSX():void {
    this.excelService.exportAsExcelFile(this.rows, 'Clienti');
    }

}
