import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { FuseSharedModule } from '@fuse/shared.module';

import { FuseContentComponent } from 'app/main/content/content.component';
import { ProducatoriModule } from './producatori/producatori/producatori.module';
import { ClientiModule} from './clienti/clienti.module';
import { AmefModule } from './amef/amef.module';
import { ContacteModule } from './contacte/contacte.module';
import { ExcelService } from '../services/excel.service';
import { F4102Module } from './f4102/f4102.module';
import { ServiceModule } from './service/service.module';
import { ConectareModule } from './conectare/conectare.module';
import { ConectareComponent } from './conectare/conectare.component';
@NgModule({
    declarations: [
        FuseContentComponent
    ],
    imports     : [
        RouterModule,
        ConectareModule,
        ContacteModule,
        F4102Module,
        ServiceModule,
        ClientiModule,
        AmefModule,
        ProducatoriModule,
        FuseSharedModule,
    ],
    exports: [
        FuseContentComponent
    ],
    providers: [ExcelService]
})
export class FuseContentModule
{
}
