import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, RouterModule, Router } from "@angular/router";
import { AuthGuardService as AuthGuard } from "app/auth/auth-guard.service";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../../environments/environment";
import {
  MatSnackBar,
  MatChipsModule,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from "@angular/material";
import { ExcelService } from "../../services/excel.service";

@Component({
  selector: "app-f4102",
  templateUrl: "./f4102.component.html",
  styleUrls: ["./f4102.component.scss"],
})
export class F4102Component implements OnInit {
  rows: any[];
  temp = [];
  loadingIndicator = true;
  selected = [];
  horizontalPosition: MatSnackBarHorizontalPosition = "center";
  verticalPosition: MatSnackBarVerticalPosition = "top";
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    public snackBar: MatSnackBar,
    private excelService: ExcelService
  ) {}

  ngOnInit() {
    this.getAMEF();
  }

  getAMEF() {
    this.http
      .get(environment.url + "/api/amef/f-amef/")
      .subscribe((amef: any) => {
        this.temp = [...amef];
        this.rows = amef;
        console.log(this.rows);
        this.loadingIndicator = false;
        this.rows = [...this.rows];
      });
  }

  DetaliiAMEF() {
    this.router.navigate(["amef/detalii", this.selected[0].serie]);
  }

  // de vazut
  SeriaUpdateFilter(event) {
    const val = event.target.value.toLowerCase();

    // filter our data
    const temp = this.temp.filter(function (d) {
      return d.serie.toLowerCase().indexOf(val) !== -1 || !val;
    });

    // update the rows
    this.rows = temp;
    // Whenever the filter changes, always go back to the first page
  }
  NUIUpdateFilter(event) {
    const val = event.target.value.toLowerCase();

    // filter our data
    const temp = this.temp.filter(function (d) {
      return d.SeriaFiscala.toLowerCase().indexOf(val) !== -1 || !val;
    });

    // update the rows
    this.rows = temp;
    // Whenever the filter changes, always go back to the first page
  }
  ClientUpdateFilter(event) {
    const val = event.target.value.toLowerCase();

    // filter our data
    const temp = this.temp.filter(function (d) {
      if (d.client_nume) {
        return d.client_nume.toLowerCase().indexOf(val) !== -1 || !val;
      }
    });

    // update the rows
    this.rows = temp;
    // Whenever the filter changes, always go back to the first page
  }
  FiscalizareUpdateFilter(event) {
    const val = event.target.value.toLowerCase();

    // filter our data
    const temp = this.temp.filter(function (d) {
      return d.data_fiscalizare.toLowerCase().indexOf(val) !== -1 || !val;
    });

    // update the rows
    this.rows = temp;
    // Whenever the filter changes, always go back to the first page
  }

  F4102() {
    var data = [];
    for (var _j = 0; _j < this.selected.length; _j++) {
      data.push({
        serie: this.selected[_j]["serie"],
        model: this.selected[_j]["model"],
        f4102: !this.selected[_j]["f4102"],
      });
    }
    for (var _j = 0; _j < this.selected.length; _j++) {
      this.http
        .put(
          environment.url + "/api/amef/v-amef/" + this.selected[_j].serie + "/",
          data[_j]
        )
        .subscribe(() => {});
    }
    this.getAMEF();
    this.selected = [];
  }

  exportAsXLSX(): void {
    this.excelService.exportAsExcelFile(this.rows, "Case de marcat");
  }
  //cui, nume, contract service
  //SeriaFiscala, Adresa,
  onSelect({ selected }) {
    this.selected.splice(0, this.selected.length);
    this.selected.push(...selected);
  }
}
